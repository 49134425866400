import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Button, Grid, Row, Col, Spin, Input } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { UsersTable } from 'components/ManageUsers/components';
import { UserAlterDrawer, UserInviteDrawer } from 'commons/Header/components';
import { useDispatch, useSelector } from 'react-redux';
import { listUsersStart } from '../../../../store/ducks/listUsersClient/actions';
import { shortId } from 'helpers/shortId';
import {
  isConciler,
  isPagSeguro,
  isPartner,
  isSuperAdmin,
} from 'helpers/authorizationService';
import { resetCheckInvitationEmail } from 'store/ducks/checkInvitationEmail/actions';

const TabsNavigation = () => {
  const dispatch = useDispatch();
  const { Search } = Input;
  const users = useSelector((state) => state.listUsers.users);
  const [updatedUsers, setUpdatedUsers] = useState(users);
  const allUsers = useSelector((state) => state.listUsers.allUsers);
  const loadingUsers = useSelector((state) => state.listUsers.loading);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredAllUsers, setFilteredAllUsers] = useState([]);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    dispatch(
      listUsersStart({
        scopeId: undefined,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const [modalVisible, toggleModal] = useState(false);
  const [modalAlterVisible, toggleAlterModal] = useState(false);
  const [userSelect, setUserSelect] = useState({
    name: '',
    scope_id: '',
    profile_id: '',
    email: '',
  });

  const openUserAlterDrawer = (user) => {
    setUserSelect(user);
    toggleAlterModal(true);
  };

  const closeUserAlterDrawer = () => {
    toggleAlterModal(false);
  };

  const openUserInviteDrawer = () => {
    toggleModal(true);
  };

  const closeUserInviteDrawer = () => {
    toggleModal(false);
    dispatch(resetCheckInvitationEmail({}));
  };
  const filterClientsScope = (type) => {
    return filteredUsers.filter((item) => {
      return item.scope_id === type;
    });
  };

  useEffect(() => {
    if (users !== null && users.length > 0) {
      setUpdatedUsers(
        users.map((user) => {
          // eslint-disable-next-line no-param-reassign
          user.key = shortId();
          return user;
        })
      );
    }
  }, [users]);

  useEffect(() => {
    if (filteredUsers.length === 0) {
      setFilteredAllUsers(allUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);

  useEffect(() => {
    setFilteredUsers(updatedUsers);
  }, [updatedUsers]);

  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredUsers]);

  const onSearch = (value) => {
    let _filteredUsers = [];
    let _filteredAllUsers = [];
    if (value === '' || value === ' ') {
      _filteredUsers = users;
      _filteredAllUsers = allUsers;
    } else {
      _filteredUsers = users.filter((user) => {
        let found = false;
        Object.values(user).forEach((userValue) => {
          if (String(userValue)?.includes(value)) {
            found = true;
          }
        });
        return found;
      });
      _filteredAllUsers = allUsers.filter((user) => {
        let found = false;
        Object.values(user).forEach((userValue) => {
          if (String(userValue)?.includes(value)) {
            found = true;
          }
        });
        return found;
      });
    }
    setFilteredUsers(_filteredUsers);
    setFilteredAllUsers(_filteredAllUsers);
  };

  if (loadingUsers) {
    return (
      <Row style={{ height: '100%' }} justify="center" align="middle">
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  }

  const items = [
    {
      label: `Clientes (${filterClientsScope(1).length})`,
      key: 'clients',
      children: (
        <UsersTable
          data={filterClientsScope(1)}
          alterDrawerUser={openUserAlterDrawer}
        />
      ),
    },
    isConciler() || isPartner()
      ? {
          label: `Parceiros (${filterClientsScope(2).length})`,
          key: 'partners',
          children: (
            <UsersTable
              data={filterClientsScope(2)}
              alterDrawerUser={openUserAlterDrawer}
            />
          ),
        }
      : {},
    isConciler() || isPagSeguro()
      ? {
          label: `PagSeguro (${filterClientsScope(4).length})`,
          key: 'pagseguro',
          children: (
            <UsersTable
              data={filterClientsScope(4)}
              alterDrawerUser={openUserAlterDrawer}
            />
          ),
        }
      : {},
    isConciler()
      ? {
          label: `Concilers (${filterClientsScope(3).length})`,
          key: 'concil',
          children: (
            <UsersTable
              data={filterClientsScope(3)}
              alterDrawerUser={openUserAlterDrawer}
            />
          ),
        }
      : {},
    isConciler()
      ? {
          label: `Todos (${filteredUsers?.length})`,
          key: 'all',
          children: (
            <UsersTable
              data={filteredUsers}
              alterDrawerUser={openUserAlterDrawer}
            />
          ),
        }
      : {},
    isSuperAdmin()
      ? {
          label: `Geral (${filteredAllUsers?.length})`,
          key: 'general',
          children: (
            <UsersTable
              data={filteredAllUsers}
              alterDrawerUser={openUserAlterDrawer}
            />
          ),
        }
      : {},
  ];

  return (
    <>
      <UserInviteDrawer
        visible={modalVisible}
        onClose={closeUserInviteDrawer}
      />
      <UserAlterDrawer
        visible={modalAlterVisible}
        onClose={closeUserAlterDrawer}
        user={userSelect}
      />
      <Tabs
        items={items}
        style={{ marginTop: '3rem' }}
        defaultActiveKey="clients"
        tabBarExtraContent={
          <>
            {breakpoint?.md && (
              <>
                <Search
                  placeholder="Buscar usuários"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
                <Button
                  onClick={openUserInviteDrawer}
                  icon={<UserAddOutlined />}
                  type="primary"
                  style={{ marginLeft: '1rem' }}
                >
                  Convidar usuário
                </Button>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default TabsNavigation;
