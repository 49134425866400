import React, { useContext, useEffect, useState } from 'react';
import { OfxParametersContext } from 'components/OfxParameters/OfxParameters';
import { useReport } from 'utils/hooks/useReport';
import { ExportDropdown } from 'commons/ExportDropdown';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import * as St from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const OfxParametersTable = () => {
  const [renderTable, setRenderTable] = useState(true);
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);
  const { setDropdownOption, loading: notificationsLoading } = useReport({
    fileName: 'relatorio_regras_ofx',
    table: 'ofx-rules',
  });

  const {
    loading,
    loadingAcquirer,
    onTablePaginationOrFiltersOrSorterChange,
    pagination,
    listData,
    columnList,
    searchText,
    onSearch,
    onChangeSearch,
    total,
    handleAddModal,
    handleClearFilters,
  } = useContext(OfxParametersContext);

  const callHandleClearFilters = () => {
    handleClearFilters();
    setRenderTable(false);
  };

  useEffect(() => {
    setRenderTable(true);
  }, [renderTable]);

  useEffect(() => {
    if (total > MAX_EXCEL_ROWS) {
      setDisableExcel(true);
    } else {
      setDisableExcel(false);
    }

    if (total > MAX_CSV_ROWS) {
      setDisableCSV(true);
    } else {
      setDisableCSV(false);
    }
  }, [total]);

  const dataSource = listData.map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <St.Container>
      <St.Row>
        <St.TableHeader>
          <St.SearchInput
            placeholder="Procurar adquirente ou descrição"
            value={searchText}
            onSearch={onSearch}
            onChange={onChangeSearch}
            disabled={loading}
          />
          <St.AddButton
            type="primary"
            disabled={loadingAcquirer || loading || notificationsLoading}
            onClick={handleAddModal}
          >
            <St.AddIcon />
            Adicionar regra
          </St.AddButton>
          <St.ClearFiltersButton
            disabled={loadingAcquirer || loading || notificationsLoading}
            onClick={callHandleClearFilters}
          >
            Limpar filtros
          </St.ClearFiltersButton>
          <ExportDropdown
            buttonType="default"
            loading={loading || notificationsLoading}
            setDropdownOption={setDropdownOption}
            disableExcel={disableExcel}
            disableCSV={disableCSV}
            dataTestPrefix="table-header"
            {...dataTestIdHandler('table-header', 'export-button')}
          />
        </St.TableHeader>
        {renderTable && (
          <St.Table
            loading={loading || loadingAcquirer}
            onChange={onTablePaginationOrFiltersOrSorterChange}
            pagination={pagination}
            dataSource={dataSource}
            columns={columnList}
            showSorterTooltip={false}
            scroll={{
              x: '100%',
              y: window.innerHeight - 400,
            }}
          />
        )}
      </St.Row>
    </St.Container>
  );
};

export default OfxParametersTable;
