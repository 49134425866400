import { action } from 'typesafe-actions';
import { actionTypes } from 'store/ducks/checkInvitationEmail/types';

export const checkInvitationEmailStart = (data) =>
  action(actionTypes.CHECK_INVITATION_EMAIL_START, data);
export const checkInvitationEmailSuccess = (data) =>
  action(actionTypes.CHECK_INVITATION_EMAIL_SUCCESS, data);
export const checkInvitationEmailError = (errorMessage, error) =>
  action(
    actionTypes.CHECK_INVITATION_EMAIL_ERROR,
    { errorMessage },
    undefined,
    { error }
  );
export const resetCheckInvitationEmail = (data) => {
  return action(actionTypes.RESET_CHECK_INVITATION_EMAIL, data);
};
