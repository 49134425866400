import {
  columnFilters,
  getDefaultColumns,
  onColumnFilter,
} from 'helpers/generalService';
import { StTableColumnTitle } from '../../../../../commons/Table/styled';
import React from 'react';
import Actions from './Actions';
import store from 'store';
import { Row, Col, Spin, Tooltip } from 'antd';

import {
  importFilesFiltersStart,
  importFilesFiltersZeroLength,
} from '../../../../../store/ducks/filters/importFiles/actions';
import FilterDropdown from 'commons/Table/components/FilterDropdown';
import { LoadingOutlined } from '@ant-design/icons';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const columns = (apiFilters, toParamsFilters, currentPage) => [
  {
    title: 'Data de envio',
    dataIndex: 'file_created_at',
    filteredValue: getDefaultColumns(toParamsFilters, 'file_created_at'),
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'file_created_at') : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'file_created_at'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'file_created_at',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="file_created_at"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 160,
    align: 'center',
  },
  {
    title: 'Nome do Arquivo',
    dataIndex: 'file_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'file_name'),
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'file_name') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'file_name'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'file_name',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="file_name"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 300,
    align: 'center',
  },
  {
    title: 'Banco',
    dataIndex: 'short_name',
    filteredValue: getDefaultColumns(toParamsFilters, 'short_name'),
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'short_name') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'short_name'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'short_name',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="short_name"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 150,
    align: 'center',
  },
  {
    title: 'Agência',
    dataIndex: 'bank_branch',
    filteredValue: getDefaultColumns(toParamsFilters, 'bank_branch'),
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'bank_branch') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'bank_branch'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'bank_branch',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="bank_branch"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 150,
    align: 'center',
  },
  {
    title: 'Conta',
    dataIndex: 'bank_account',
    filteredValue: getDefaultColumns(toParamsFilters, 'bank_account'),
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'bank_account') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'bank_account'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'bank_account',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="bank_account"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 200,
    align: 'center',
  },
  {
    title: 'Início',
    dataIndex: 'start_date',
    filteredValue: getDefaultColumns(toParamsFilters, 'start_date'),
    filters:
      apiFilters.length > 0 ? columnFilters(apiFilters, 'start_date') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'start_date'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'start_date',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="start_date"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 140,
    align: 'center',
  },
  {
    title: 'Fim',
    dataIndex: 'end_date',
    filteredValue: getDefaultColumns(toParamsFilters, 'end_date'),
    filters: apiFilters.length > 0 ? columnFilters(apiFilters, 'end_date') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'end_date'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'end_date',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="end_date"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 140,
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    filteredValue: getDefaultColumns(toParamsFilters, 'status'),
    filters: apiFilters.length > 0 ? columnFilters(apiFilters, 'status') : [],
    onFilter: (value, record) => onColumnFilter(value, record, 'status'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'status',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="status"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    render: (_, record) => {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return (
        <Row gutter={[16, 0]}>
          <Col
            {...dataTestIdHandler(
              'import-file',
              `status-${record?.file_trace_identifier}`
            )}
          >
            {record?.status}
          </Col>
          {record?.status === 'IMPORTANDO' || record?.status === 'REMOVENDO' ? (
            <Col
              {...dataTestIdHandler(
                'import-file',
                `status-${record?.file_trace_identifier}`
              )}
            >
              <Tooltip title="Uma atualização dos dados ocorrerá a cada 1 minuto.">
                <Spin indicator={antIcon} size="small" />
              </Tooltip>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      );
    },
    width: 150,
    align: 'center',
  },
  {
    title: 'ID',
    dataIndex: 'file_trace_identifier',
    filteredValue: getDefaultColumns(toParamsFilters, 'file_trace_identifier'),
    filters:
      apiFilters.length > 0
        ? columnFilters(apiFilters, 'file_trace_identifier')
        : [],
    onFilter: (value, record) =>
      onColumnFilter(value, record, 'file_trace_identifier'),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // This "zero length" dispatch is utterly important,
        // in order to keep each column state independent (Not passing data from one column to the next)
        store.dispatch(importFilesFiltersZeroLength());
        store.dispatch(
          importFilesFiltersStart({
            startDate: store.getState().updateScreenToScreenDate?.startDate,
            endDate: store.getState().updateScreenToScreenDate?.endDate,
            columnName: 'file_trace_identifier',
            offset: 0,
            limit: store.getState().importFilesFilters?.offsetShift,
          })
        );
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <FilterDropdown
          apiFilters={apiFilters}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          filterAction={importFilesFiltersStart}
          clearAction={importFilesFiltersZeroLength}
          columnName="file_trace_identifier"
          storeName="importFilesFilters"
        />
      );
    },
    filterMultiple: true,
    sorter: {
      multiple: 1,
    },
    width: 185,
    align: 'center',
    className: 'hide',
  },
  {
    title: () => {
      return <StTableColumnTitle>Ações</StTableColumnTitle>;
    },
    dataIndex: 'actions',
    width: 150,
    render: (_, record) => {
      return <Actions currentRow={record} currentPage={currentPage} />;
    },
    align: 'center',
    fixed: 'right',
  },
];

export default columns;
