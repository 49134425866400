import { actionTypes } from 'store/ducks/checkInvitationEmail/types';

const INITIAL_STATE = {
  error: undefined,
  errorMessage: undefined,
  loading: false,
  isError: false,
  hasUserClientAssociation: true,
  userData: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CHECK_INVITATION_EMAIL_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    case actionTypes.CHECK_INVITATION_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        isError: false,
        errorMessage: undefined,
        hasUserClientAssociation: action.payload.hasUserClientAssociation,
        userData: action.payload.userData,
      };
    case actionTypes.CHECK_INVITATION_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
        errorMessage: action.payload,
        isError: true,
      };
    case actionTypes.RESET_CHECK_INVITATION_EMAIL:
      return {
        ...state,
        userData: {},
        loading: false,
        error: undefined,
        errorMessage: undefined,
        isError: false,
      };
    default:
      return state;
  }
};

export default reducer;
