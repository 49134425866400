import React from 'react';
import { Card, Row, Col, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { ErrorCard } from 'commons/ErrorCard';
import { StSeeDetailsButton } from 'components/Intermediate/styled';
import { StIntermediateCard } from 'commons/Intermediate/styled';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const SummaryCard = ({ data, isError, loading }) => {
  const dataTestPrefix = 'summary-card';
  const goToReports = () => {
    customHistory.push('/conciliacao-vendas-relatorios');
  };

  if (isError) {
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg={10} md={18}>
          <Card style={{ borderRadius: 4 }} loading={loading}>
            <ErrorCard
              title="Dados indisponíveis"
              reason="Não conseguimos buscar os dados, mas já estamos trabalhando para normalizar a situação."
              recommendation="Tente atualizar a página em alguns instantes."
            />
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <Row style={{ marginTop: '2rem' }}>
      <Col xxl={12} xl={18} lg={18} md={24} sm={24} xs={24}>
        <Skeleton loading={loading} active paragraph={{ rows: 6 }}>
          <StIntermediateCard
            loading={loading}
            actions={[
              <div style={{ textAlign: 'end' }}>
                <StSeeDetailsButton
                  type="link"
                  size="small"
                  fontSize="1.75rem"
                  onClick={goToReports}
                  {...dataTestIdHandler(dataTestPrefix, 'go-to-reports')}
                >
                  Ver relatório
                </StSeeDetailsButton>
              </div>,
            ]}
          >
            <Row>
              <Col>
                <TitleAndIcon
                  dataTestPrefix={dataTestPrefix}
                  titleText="Resumo total"
                  titleFontSize="18px"
                />
              </Col>
            </Row>
            <Row>
              <Col
                xxl={12}
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ marginTop: 16 }}
              >
                <Row>
                  <TitleAndIcon
                    titleText="Estabelecimentos"
                    titleFontSize="14px"
                    dataTestPrefix="sales-reconciliation-merchants"
                  />
                </Row>

                <Row justify="space-between">
                  <Col span={8}>
                    <LabelAndValue
                      label="Total de vendas"
                      labelColor={colors.gray7}
                      value={data?.customer?.totalAmount}
                      valueTextSize="medium"
                      textAlign="start"
                      strong
                      dataTestPrefix="sales-reconciliation-total-sales-merchants"
                    />
                  </Col>
                  <Col span={8}>
                    <LabelAndValue
                      label="Conciliadas"
                      labelColor={colors.gray7}
                      value={data?.customer?.reconciledAmount}
                      valueTextSize="medium"
                    />
                  </Col>
                  <Col span={8}>
                    <LabelAndValue
                      label="Não conciliadas"
                      labelColor={colors.gray7}
                      value={data?.customer?.pendentAmount}
                      valueColor={
                        data?.customer?.rawPendentAmount !== 0
                          ? colors.red5
                          : colors.gray11
                      }
                      valueTextSize="medium"
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xxl={12}
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ marginTop: 16 }}
              >
                <Row>
                  <TitleAndIcon
                    titleText="Adquirentes"
                    titleFontSize="14px"
                    dataTestPrefix="sales-reconciliation-acquirers"
                  />
                </Row>

                <Row justify="space-between">
                  <Col span={8}>
                    <LabelAndValue
                      label="Total de vendas"
                      labelColor={colors.gray7}
                      value={data?.acquirer?.totalAmount}
                      valueTextSize="medium"
                      textAlign="start"
                      strong
                      dataTestPrefix="sales-reconciliation-total-sales-acquirers"
                    />
                  </Col>
                  <Col span={8}>
                    <LabelAndValue
                      label="Conciliadas"
                      labelColor={colors.gray7}
                      value={data?.acquirer?.reconciledAmount}
                      valueTextSize="medium"
                    />
                  </Col>
                  <Col span={8}>
                    <LabelAndValue
                      label="Não conciliadas"
                      labelColor={colors.gray7}
                      value={data?.acquirer?.pendentAmount}
                      valueColor={
                        data?.acquirer?.rawPendentAmount !== 0
                          ? colors.red5
                          : colors.gray11
                      }
                      valueTextSize="medium"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </StIntermediateCard>
        </Skeleton>
      </Col>
    </Row>
  );
};

SummaryCard.propTypes = {
  data: PropTypes.shape({
    sales_quantity: PropTypes.number,
    ticket_avarage: PropTypes.string,
    sales_amount: PropTypes.string,
  }).isRequired,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummaryCard;
