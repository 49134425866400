/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, Row, Spin, Tooltip, Typography } from 'antd';
import { CopyOutlined, WarningOutlined } from '@ant-design/icons';
import {
  tokenSandboxDeleteStart,
  tokenSandboxPostStart,
  tokenSandboxPutStart,
} from 'store/ducks/tokenSandbox/actions';
import { copyAppKey } from '../../utils/copyAppKey';
import { handleStatus } from '../../utils/handleStatus';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const TokenSandboxCard = ({
  sandboxRequestDeleteAt,
  sandboxDeleteLoading,
  sandboxPostLoading,
  sandboxIsActive,
  rowClientId,
  sandboxGetLoading,
  sandboxAppKey,
  setUserHasCopied,
  sandboxPutLoading,
  sandboxEndDate,
  today,
  isApiConnected,
  updateBranchDataLoading,
  getListOfClients,
}) => {
  const dataTestPrefix = 'branch-management-token-sandbox';
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [preDisable, setPreDisable] = useState(false);

  const tokenSandboxHasBeenPosted = useSelector(
    (state) => state.tokenSandbox.tokenSandboxHasBeenPosted
  );
  const tokenSandboxHasBeenDeleted = useSelector(
    (state) => state.tokenSandbox.tokenSandboxHasBeenDeleted
  );
  const tokenSandboxLoadingDelete = useSelector(
    (state) => state.tokenSandbox.loadingDelete
  );

  useEffect(() => {
    if (tokenSandboxHasBeenDeleted && preDisable) {
      setPreDisable(false);
      getListOfClients();
    }

    if (tokenSandboxHasBeenPosted) {
      getListOfClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSandboxHasBeenDeleted, tokenSandboxHasBeenPosted]);

  return (
    <Card
      headStyle={{
        backgroundColor: preDisable && colors.gold1,
        display: preDisable && 'flex',
      }}
      title={
        !preDisable ? (
          'Sandbox'
        ) : (
          <div
            style={{
              width: '100%',
              backgroundColor: colors.gold1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <WarningOutlined
              style={{ color: colors.gold6, fontSize: '21px' }}
            />
            <Text
              style={{
                fontSize: '14px',
                fontWeight: '400',
                marginLeft: '17.5px',
              }}
            >
              Tem certeza que deseja desativar este ambiente?
            </Text>
            <Button
              type="link"
              style={{
                color: colors.primary6,
                fontSize: '14px',
              }}
              disabled={tokenSandboxLoadingDelete}
              onClick={() => {
                setPreDisable(false);
              }}
            >
              Voltar
            </Button>
            {!sandboxDeleteLoading && !updateBranchDataLoading ? (
              <Button
                type="link"
                style={{ color: colors.red7 }}
                onClick={() => {
                  if (sandboxIsActive && preDisable) {
                    dispatch(
                      tokenSandboxDeleteStart({
                        clientId: rowClientId,
                        type: 'SANDBOX',
                      })
                    );
                  }
                }}
              >
                Desativar
              </Button>
            ) : (
              <Spin />
            )}
          </div>
        )
      }
      extra={
        preDisable ? (
          <></>
        ) : !sandboxGetLoading &&
          !preDisable &&
          !sandboxPostLoading &&
          !updateBranchDataLoading ? (
          // eslint-disable-next-line react/jsx-indent
          <Text
            style={{
              color:
                sandboxIsActive && isApiConnected
                  ? colors.red7
                  : !sandboxIsActive && isApiConnected
                    ? colors.primary6
                    : !isApiConnected && '#bdbebe',
              cursor: isApiConnected ? 'pointer' : 'not-allowed',
            }}
            onClick={() => {
              if (isApiConnected && sandboxIsActive && !preDisable) {
                setPreDisable(true);
              }
              if (isApiConnected && !sandboxIsActive && !preDisable) {
                dispatch(
                  tokenSandboxPostStart({
                    clientId: rowClientId,
                    type: 'SANDBOX',
                  })
                );
              }
            }}
            {...dataTestIdHandler(dataTestPrefix, 'handle-action')}
          >
            {sandboxIsActive && 'Desativar'}
            {!sandboxIsActive &&
              (sandboxRequestDeleteAt === '' ||
                sandboxRequestDeleteAt === null) &&
              'Ativar'}
            {!sandboxIsActive &&
              sandboxRequestDeleteAt !== '' &&
              sandboxRequestDeleteAt !== null &&
              sandboxRequestDeleteAt.startsWith('20') &&
              'Reativar'}
          </Text>
        ) : (
          <Spin />
        )
      }
      style={{ width: '100%' }}
      bodyStyle={{
        // prettier-ignore
        padding: (!sandboxIsActive && sandboxRequestDeleteAt === null) || (!sandboxIsActive && sandboxRequestDeleteAt !== null && !sandboxRequestDeleteAt.startsWith('20')) ? 0 : (!sandboxIsActive && sandboxRequestDeleteAt.startsWith('20')) && '24px',
      }}
    >
      {(sandboxIsActive && !sandboxGetLoading) ||
      (sandboxIsActive && sandboxRequestDeleteAt === '') ||
      (!sandboxIsActive &&
        sandboxRequestDeleteAt !== '' &&
        sandboxRequestDeleteAt !== null) ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <Row>
            <Text style={{ color: colors.gray8, fontSize: '12px' }}>
              Status
            </Text>
          </Row>
          <Row style={{ marginBottom: '16px' }}>
            <Text
              style={{
                color: colors.gray8,
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              {handleStatus(
                sandboxIsActive,
                sandboxRequestDeleteAt,
                sandboxEndDate,
                today
              )}
            </Text>
          </Row>

          <Row>
            <Text style={{ color: colors.gray8, fontSize: '14px' }}>Token</Text>
          </Row>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ display: 'flex', width: 'calc(100% - 110px)' }}
              {...dataTestIdHandler(dataTestPrefix, 'app-key-container')}
            >
              <Input
                value={sandboxAppKey}
                suffix={
                  <Tooltip title="Clique para copiar">
                    <CopyOutlined
                      style={{ color: colors.primary6 }}
                      onClick={() =>
                        copyAppKey(sandboxAppKey, setUserHasCopied)
                      }
                      disabled={updateBranchDataLoading}
                      {...dataTestIdHandler(
                        dataTestPrefix,
                        'copy-sandbox-app-key'
                      )}
                    />
                  </Tooltip>
                }
                disabled={sandboxPutLoading}
                {...dataTestIdHandler(dataTestPrefix, 'app-key')}
              />
            </div>
            <div>
              {!sandboxPutLoading && !updateBranchDataLoading ? (
                <Button
                  style={{
                    color:
                      !sandboxIsActive && sandboxRequestDeleteAt !== ''
                        ? colors.gray8
                        : colors.primary6,
                    fontSize: '14px',
                  }}
                  type="link"
                  disabled={
                    sandboxPutLoading ||
                    (!sandboxIsActive && sandboxRequestDeleteAt !== '')
                  }
                  onClick={() =>
                    dispatch(
                      tokenSandboxPutStart({
                        type: 'SANDBOX',
                        clientId: rowClientId,
                      })
                    )
                  }
                  {...dataTestIdHandler(dataTestPrefix, 'generate-new-app-key')}
                >
                  Gerar novo
                </Button>
              ) : (
                <Spin style={{ marginRight: '30px', marginTop: '5px' }} />
              )}
            </div>
          </Row>
        </>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default TokenSandboxCard;
