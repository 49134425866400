import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Col, Modal, Row, Spin } from 'antd';
import errorHandler from 'helpers/errorHandler';
import { getSessionClientId } from 'helpers/sessionService';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listBankConciliationTransactionsStart,
  resetHasNoTransactionsStart,
  updateThroughImportFilesStart,
} from 'store/ducks/bankConciliation/actions';
import { colors } from 'styles/colors';
import { downloadFile } from '../../../../../services/importFiles';
import {
  removeFileStart,
  updateCurrentPageStart,
  updateFileDateStart,
} from '../../../../../store/ducks/importFiles/actions';
import { StLink } from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const Actions = ({ currentRow, currentPage }) => {
  const dispatch = useDispatch();
  const loadingRemove = useSelector((state) => state.importFiles.loadingRemove);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const onDownload = () => {
    setLoadingDownload(true);
    downloadFile(currentRow?.file_trace_identifier)
      .then((response) => {
        setLoadingDownload(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', currentRow?.file_name + '.ofx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        errorHandler(
          `Erro ao baixar arquivo: ${error?.message?.split('Error')[0]}`,
          error
        );
        setLoadingDownload(false);
      });
  };
  const onDelete = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja excluir?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Ao confirmar a exclusão deste extrato a operação não poderá ser desfeita.',
      okText: 'Excluir',
      cancelText: 'Cancelar',
      onOk: () => {
        dispatch(
          removeFileStart({
            client_id: getSessionClientId(),
            identifier: currentRow?.file_trace_identifier,
          })
        );
      },
    });
  };

  const fullFileName = currentRow?.path_abs.split('/').pop();

  const handleAnalisar = () => {
    dispatch(resetHasNoTransactionsStart({}));

    dispatch(
      updateFileDateStart({
        fileStartDate: currentRow?.start_date,
        fileEndDate: currentRow?.end_date,
      })
    );

    dispatch(
      updateThroughImportFilesStart({
        throughImportFiles: true,
        fileName: fullFileName,
      })
    );

    dispatch(
      updateCurrentPageStart({
        currentPage,
      })
    );

    dispatch(
      listBankConciliationTransactionsStart({
        bank:
          currentRow?.short_name === undefined || currentRow?.short_name === ''
            ? sessionStorage.getItem('tr_report_bank')
            : currentRow?.short_name,
        bank_branch:
          currentRow?.bank_branch === undefined ||
          currentRow?.bank_branch === ''
            ? sessionStorage.getItem('tr_report_bankBranch')
            : currentRow?.bank_branch,
        bank_account:
          currentRow?.bank_account === undefined ||
          currentRow?.bank_account === ''
            ? sessionStorage.getItem('tr_report_bankAccount')
            : currentRow?.bank_account,
        start_date:
          currentRow?.start_date === undefined || currentRow?.startDate === ''
            ? sessionStorage.getItem('tr_report_startDate')
            : currentRow?.start_date,
        end_date:
          currentRow?.end_date === undefined || currentRow?.end_date === ''
            ? sessionStorage.getItem('tr_report_endDate')
            : currentRow?.end_date,
        account_id:
          currentRow?.account_id === undefined || currentRow?.account_id === ''
            ? Number(sessionStorage.getItem('tr_report_accountId'))
            : currentRow?.account_id,
        totalizersNotRequired: true,
      })
    );
  };

  return (
    <>
      <Row justify="space-between">
        <Col>
          <StLink
            onClick={handleAnalisar}
            disabled={currentRow?.status !== 'IMPORTADO'}
            status={currentRow?.status}
            account_id={currentRow?.account_id}
            {...dataTestIdHandler(
              'ofx',
              `to-analyze-${currentRow?.file_trace_identifier}`
            )}
          >
            Analisar
          </StLink>
        </Col>
        <Col>
          {loadingDownload ? (
            <Spin />
          ) : (
            <DownloadOutlined onClick={onDownload} />
          )}
        </Col>
        <Col>
          {loadingRemove ? (
            <Spin />
          ) : (
            <DeleteOutlined style={{ color: colors.red6 }} onClick={onDelete} />
          )}
        </Col>
      </Row>
    </>
  );
};

Actions.propTypes = {};

export default Actions;
