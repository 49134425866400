/* eslint-disable react-hooks/exhaustive-deps,react/no-children-prop,no-plusplus,object-shorthand,react/no-this-in-sfc,no-unused-vars,no-restricted-globals */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import {
  ArrowRightOutlined,
  BankOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { listBanksDash } from '../../../../store/ducks/banks/listBanksDash/actions';
import BankLegendGrid from './BankLegendGrid';
import CardContainer from 'commons/CardContainer';
import { defaults, Doughnut } from 'react-chartjs-3';
import {
  StDashboardFooterCol,
  StDashboardFooterRow,
  StDashboardTag,
  StDashboardFooterCardUpperContainer,
  StHoverClickTitle,
  StHoverClickValue,
  StDropdownContent,
} from 'components/Dashboard/styled';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { updateScreenToScreenBanksPeriodStart } from 'store/ducks/updateScreenToScreenPeriod/actions';
import {
  currentDayMinusOne,
  endOfPreviousMonth,
  startOfCurrentMonth,
  startOfPreviousMonth,
} from 'helpers/dates';
import dayjs from 'dayjs';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

defaults.global.legend.position = 'bottom';
defaults.global.legend.align = 'start';
defaults.global.legend.labels = {
  boxWidth: 12,
  fontSize: 14,
  padding: 10,
};
defaults.global.legend.rtl = false;

const BanksCard = ({
  startDateBanks,
  endDateBanks,
  setStartDateBanks,
  setEndDateBanks,
  isFlipped,
  setIsFlipped,
}) => {
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const dispatch = useDispatch();
  const dropdownKeyValue = useSelector(
    (store) => store.updateScreenToScreenPeriod?.banksPeriod
  );

  const banksData = useSelector((store) => store.banksDash.banksPayment);
  const banksLoading = useSelector((store) => store.banksDash.loading);
  const permissionsLoading = useSelector(
    (state) => state?.permissions?.loading
  );

  const dataTestPrefix = 'banks-card';

  const goToBanks = () => {
    customHistory.push('/bancos-intermediario');
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDateBanks,
        endDate: endDateBanks,
      })
    );
  };

  useEffect(() => {
    switch (dropdownKeyValue) {
      case 'neste_mes':
        dispatch(
          listBanksDash({
            startDate: startOfCurrentMonth,
            endDate: currentDayMinusOne,
          })
        );
        break;
      case 'ultimo_dia':
        dispatch(
          listBanksDash({
            startDate: currentDayMinusOne,
            endDate: currentDayMinusOne,
          })
        );
        break;
      case 'ultimo_mes':
        dispatch(
          listBanksDash({
            startDate: startOfPreviousMonth,
            endDate: endOfPreviousMonth,
          })
        );
        break;
      default:
        break;
    }
  }, [dispatch, startDateBanks, endDateBanks]);

  const handleDropdownMenu = (key) => {
    switch (key) {
      case 'neste_mes':
        setStartDateBanks(startOfCurrentMonth);
        setEndDateBanks(currentDayMinusOne);
        dispatch(updateScreenToScreenBanksPeriodStart('neste_mes'));
        break;
      case 'ultimo_dia':
        setStartDateBanks(currentDayMinusOne);
        setEndDateBanks(currentDayMinusOne);
        dispatch(updateScreenToScreenBanksPeriodStart('ultimo_dia'));
        break;
      case 'ultimo_mes':
        setStartDateBanks(startOfPreviousMonth);
        setEndDateBanks(endOfPreviousMonth);
        dispatch(updateScreenToScreenBanksPeriodStart('ultimo_mes'));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleDropdownMenu(dropdownKeyValue);
  }, [dispatch]);

  const items = [
    {
      label: 'Neste mês',
      key: 'neste_mes',
      onClick: () => handleDropdownMenu('neste_mes'),
      'data-testid': `${dataTestPrefix}-neste-mes`,
    },
    {
      label: 'Último dia',
      key: 'ultimo_dia',
      onClick: () => handleDropdownMenu('ultimo_dia'),
      'data-testid': `${dataTestPrefix}-ultimo-dia`,
    },
    {
      label: 'Último mês',
      key: 'ultimo_mes',
      onClick: () => handleDropdownMenu('ultimo_mes'),
      'data-testid': `${dataTestPrefix}-ultimo-mes`,
    },
  ];

  if (banksLoading || permissionsLoading) {
    return (
      <Skeleton
        active
        paragraph={{
          rows: 12,
        }}
      />
    );
  }

  return (
    <CardContainer
      height={360}
      enforceMaxHeight
      hasFooter
      {...dataTestIdHandler(dataTestPrefix, 'container')}
    >
      <StDashboardFooterCardUpperContainer>
        <Row align="middle" justify="space-between">
          <StHoverClickTitle onClick={goToBanks}>
            <Col>
              <TitleAndIcon
                children={
                  <BankOutlined
                    style={{
                      fontSize: '24px',
                      paddingRight: '12px',
                    }}
                    {...dataTestIdHandler(dataTestPrefix, 'icon')}
                  />
                }
                iconAlt="Icone de um banco de crédito"
                titleFontSize="20px"
                titleText="Bancos"
                dataTestPrefix={dataTestPrefix}
              />
            </Col>
          </StHoverClickTitle>
          <Col {...dataTestIdHandler(dataTestPrefix, 'dropdown')}>
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              dropdownRender={(menu) => (
                <StDropdownContent>{menu}</StDropdownContent>
              )}
            >
              <StDashboardTag icon={<CalendarOutlined />}>
                {dropdownKeyValue === 'neste_mes' && 'Neste mês'}
                {dropdownKeyValue === 'ultimo_dia' && 'Último dia'}
                {dropdownKeyValue === 'ultimo_mes' && 'Último mês'}
              </StDashboardTag>
            </Dropdown>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Row align="left" style={{ marginTop: '2rem', paddingLeft: '32px' }}>
            <Col>
              <Doughnut
                width={140}
                height={140}
                options={{
                  tooltips: {
                    enabled: true,
                    callbacks: {
                      // eslint-disable-next-line func-names
                      title: function (tooltipItem, data) {
                        if (data.datasets?.length > 0) {
                          return data.datasets[0].labels[tooltipItem[0].index];
                        }
                        return 'N/A';
                      },
                      // eslint-disable-next-line func-names
                      label: function (tooltipItem, data) {
                        if (data.datasets?.length > 0) {
                          if (data.datasets[0].data[tooltipItem.index] > 1) {
                            return (
                              'R$ ' +
                              data.datasets[0].data[
                                tooltipItem.index
                              ].toLocaleString('pt-BR')
                            );
                          }
                          return '';
                        }
                        return '';
                      },
                    },
                    displayColors: false,
                  },
                  cutoutPercentage: 40,
                }}
                data={{
                  datasets: [
                    {
                      labels: banksData?.nameBanks
                        ? banksData?.nameBanks
                        : [''],
                      data:
                        banksData?.dataBanks === undefined
                          ? [1]
                          : banksData?.dataBanks,
                      backgroundColor: banksData?.colorsBank || [],
                      borderWidth: 0,
                    },
                  ],
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '2rem' }} align="end">
            <Col offset={12}>
              <StHoverClickValue onClick={goToBanks} disableHoverColor>
                <LabelAndValue
                  label="Total recebido"
                  labelColor="rgba(0,0,0,0.45)"
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(
                    !banksData?.totalAmount || banksData?.totalAmount === 0
                      ? '0'
                      : banksData?.totalAmount
                  )}
                  valueTextSize="large"
                  textAlign="end"
                  dataTestPrefix={dataTestPrefix}
                />
              </StHoverClickValue>
            </Col>
          </Row>
        </div>
        {banksData?.nameBanks?.length > 0 && (
          <BankLegendGrid banksData={banksData} />
        )}
      </StDashboardFooterCardUpperContainer>
      {isFeatureAvailable({
        key: 'dashboard-bankReconciliationCard',
      }) && (
        <StDashboardFooterRow
          justify="end"
          {...dataTestIdHandler(dataTestPrefix, 'footer')}
        >
          <StDashboardFooterCol>
            <span
              onClick={() => {
                setIsFlipped({
                  ...isFlipped,
                  bank: !isFlipped?.bank,
                });
              }}
            >
              Ver conciliação
              <ArrowRightOutlined style={{ marginLeft: '8px' }} />
            </span>{' '}
          </StDashboardFooterCol>
        </StDashboardFooterRow>
      )}
    </CardContainer>
  );
};

BanksCard.propTypes = {
  startDateBanks: PropTypes.instanceOf(dayjs).isRequired,
  endDateBanks: PropTypes.instanceOf(dayjs).isRequired,
  setStartDateBanks: PropTypes.func.isRequired,
  setEndDateBanks: PropTypes.func.isRequired,
};

export default BanksCard;
