import { call, put, delay } from 'redux-saga/effects';

import errorHandler from 'helpers/errorHandler';

import { listFiles, removeFile, uploadFile } from 'services/importFiles';
import {
  listFilesSuccess,
  listFilesError,
  uploadFileSuccess,
  uploadFileError,
  removeFileSuccess,
  removeFileError,
  updateFileDateSuccess,
  updateFileDateError,
  updateCurrentPageSuccess,
  updateCurrentPageError,
} from './actions';
import { notification } from 'antd';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export function* listFilesSaga(action) {
  try {
    const { payload } = action;
    yield delay(5000);
    const apiData = yield call(listFiles, payload);
    yield put(listFilesSuccess(apiData));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        listFilesError(err.response.data.error.message, err.response.data.error)
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(listFilesError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* removeFileSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(removeFile, payload);
    notification.success({
      message: 'O extrato selecionado foi excluído com sucesso!',
    });
    yield delay(5000);
    yield put(removeFileSuccess(apiData));
    // window.location.reload();
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        removeFileError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(removeFileError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* uploadFileSaga(action) {
  try {
    const { payload } = action;
    const apiData = yield call(uploadFile, payload);
    notification.success({
      message:
        'Extrato enviado para processamento com sucesso. Aguarde um instante.',
      props: dataTestIdHandler('import-file', 'extract-sent-for-processing'),
    });
    yield delay(5000);
    yield put(uploadFileSuccess(apiData));
  } catch (err) {
    yield put(uploadFileError('Ocorreu um erro:', err));
    errorHandler(err?.response?.data?.error?.message, err);
  }
}

export function* updateFileDateSaga(action) {
  try {
    const { payload } = action;
    yield put(updateFileDateSuccess(payload));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      yield put(
        updateFileDateError(
          err.response.data.error.message,
          err.response.data.error
        )
      );
      errorHandler(err?.response?.data?.error?.message, err);
    } else {
      yield put(updateFileDateError('Ocorreu um erro:', err));
      errorHandler('Ocorreu um erro:', err);
    }
  }
}

export function* updateCurrentPageSaga(action) {
  try {
    const { payload } = action;
    yield put(updateCurrentPageSuccess(payload));
  } catch (err) {
    yield put(updateCurrentPageError('Ocorreu um erro:', err));
    errorHandler('Ocorreu um erro:', err);
  }
}
