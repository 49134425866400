import React, { useState } from 'react';
import * as St from './styled';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Grid, Spin, Pagination } from 'antd';
import { Empty } from 'commons/Empty';
import NotificationCard from './components/NotificationCard/NotificationCard';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import {
  updateNotificationPage,
  updateNotificationPerPage,
} from 'store/ducks/notifications/actions';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const notFound = `${assetsBaseURL}/img/notFound.png`;

const Notifications = () => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const dataTestPrefix = 'notifications';
  const today = new Date();
  const formattedToday = dayjs(today).format('DD/MM - HH:mm');

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const todayNotifications = useSelector(
    (state) => state.notifications.todayNotifications
  );
  const yesterdayNotifications = useSelector(
    (state) => state.notifications.yesterdayNotifications
  );
  const oldNotifications = useSelector(
    (state) => state.notifications.oldNotifications
  );
  const notificationsLoading = useSelector(
    (state) => state.notifications.loading
  );
  const totalPages = useSelector(
    (state) => state.notifications.notificationTotalPages
  );
  const perPage = useSelector(
    (state) => state.notifications.notificationPerPage
  );
  const totalItems = useSelector(
    (state) => state.notifications.notificationTotalItems
  );
  const notificationPageFromStore = useSelector(
    (state) => state.notifications.notificationPage
  );
  const [currentPage, setCurrentPage] = useState(notificationPageFromStore);

  const handlePageChange = (pageNumber, pageSize) => {
    dispatch(
      updateNotificationPage({
        pageNumber,
      })
    );
    dispatch(
      updateNotificationPerPage({
        perPage: pageSize,
      })
    );
    setCurrentPage(pageNumber);
  };

  return (
    <St.Container $breakpoint={breakpoint}>
      <St.MainTitle>Notificações</St.MainTitle>

      <St.MainColumn>
        <St.FilesText>Arquivos</St.FilesText>
        <St.FileDescText>
          Agora você pode acompanhar por aqui suas solicitações de relatório.
        </St.FileDescText>

        {!notifications && !notificationsLoading ? (
          <Empty
            title="Você ainda não realizou uma solicitação de relatório."
            reason="Suas solicitações irão aparecer aqui."
            emptyLogo={notFound}
          />
        ) : (
          <>
            {!notificationsLoading ? (
              <>
                {/* Today Notifications */}
                {todayNotifications !== undefined &&
                  todayNotifications.length > 0 && (
                    <>
                      <St.TodayRow>
                        <St.DayText>Hoje</St.DayText>
                      </St.TodayRow>

                      <St.FilesRow>
                        {notifications !== undefined &&
                          todayNotifications !== undefined &&
                          todayNotifications.map(
                            ({
                              title,
                              status,
                              created_at: createdAt,
                              email,
                              file_name: fileName,
                              message,
                              notification_id: notificationId,
                              is_expired: isExpired,
                              notification_type: notificationType,
                            }) => {
                              const formattedDate =
                                dayjs(createdAt).format('DD/MM - HH:mm');
                              return (
                                <React.Fragment key={notificationId}>
                                  <NotificationCard
                                    title={title}
                                    status={status.trim()}
                                    notificationTime={formattedDate}
                                    today={formattedToday}
                                    email={email}
                                    fileName={fileName}
                                    message={message}
                                    notificationId={notificationId}
                                    isExpired={isExpired}
                                    notificationType={notificationType}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}
                      </St.FilesRow>
                    </>
                  )}

                {/* Yesterday Notifications */}
                {yesterdayNotifications !== undefined &&
                  yesterdayNotifications.length > 0 && (
                    <>
                      <St.OtherDaysRow>
                        <St.DayText>Ontem</St.DayText>
                      </St.OtherDaysRow>

                      <Row style={{ display: 'flex', flexDirection: 'column' }}>
                        {notifications !== undefined &&
                          yesterdayNotifications !== undefined &&
                          yesterdayNotifications.map(
                            ({
                              title,
                              status,
                              created_at: createdAt,
                              email,
                              file_name: fileName,
                              message,
                              notification_id: notificationId,
                              is_expired: isExpired,
                              notification_type: notificationType,
                            }) => {
                              const formattedDate =
                                dayjs(createdAt).format('DD/MM - HH:mm');

                              return (
                                <React.Fragment key={notificationId}>
                                  <NotificationCard
                                    title={title}
                                    status={status.trim()}
                                    notificationTime={formattedDate}
                                    today={formattedToday}
                                    email={email}
                                    fileName={fileName}
                                    message={message}
                                    notificationId={notificationId}
                                    isExpired={isExpired}
                                    notificationType={notificationType}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}
                      </Row>
                    </>
                  )}

                {/* Old Notifications */}
                {oldNotifications !== undefined &&
                  oldNotifications.length > 0 && (
                    <>
                      <St.OtherDaysRow>
                        <St.DayText>Mensagens antigas</St.DayText>
                      </St.OtherDaysRow>

                      <Row style={{ display: 'flex', flexDirection: 'column' }}>
                        {notifications !== undefined &&
                          oldNotifications !== undefined &&
                          oldNotifications.map(
                            ({
                              title,
                              status,
                              created_at: createdAt,
                              email,
                              file_name: fileName,
                              message,
                              notification_id: notificationId,
                              is_expired: isExpired,
                              notification_type: notificationType,
                            }) => {
                              const formattedDate =
                                dayjs(createdAt).format('DD/MM - HH:mm');
                              return (
                                <React.Fragment key={notificationId}>
                                  <NotificationCard
                                    title={title}
                                    status={status.trim()}
                                    notificationTime={formattedDate}
                                    today={formattedToday}
                                    email={email}
                                    fileName={fileName}
                                    message={message}
                                    notificationId={notificationId}
                                    isExpired={isExpired}
                                    notificationType={notificationType}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}
                      </Row>
                    </>
                  )}

                {/* Pagination */}
                <St.PaginationContainer
                  {...dataTestIdHandler(dataTestPrefix, 'pagination-container')}
                >
                  {totalPages !== 0 && perPage !== undefined && (
                    <Pagination
                      total={totalItems}
                      pageSize={perPage}
                      current={currentPage}
                      showQuickJumper
                      showSizeChange
                      totalBoundaryShowSizeChanger={10}
                      onChange={(pageNumber, pageSize) => {
                        handlePageChange(pageNumber, pageSize);
                      }}
                    />
                  )}
                </St.PaginationContainer>
              </>
            ) : (
              <Spin />
            )}
          </>
        )}
      </St.MainColumn>
    </St.Container>
  );
};

export default Notifications;
