import {
  Button,
  Col,
  Drawer,
  Form,
  Grid,
  Input,
  Modal,
  Radio,
  Row,
  Typography,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import profiles from 'constants/profiles';
import scopes from 'constants/scopes';
import {
  isAdmin,
  isClient,
  isConciler,
  isManagerial,
  isOperational,
  isPagSeguro,
  isPartner,
  isSuperAdmin,
} from 'helpers/authorizationService';
import errorHandler from 'helpers/errorHandler';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'styles/colors';
import {
  alterProfileScope,
  setLoadingProfile,
} from '../../../../store/ducks/alterProfileScope/actions';
import { listUsersStart } from '../../../../store/ducks/listUsersClient/actions';
import {
  StContainer,
  StModalBodyWrapper,
  StModalBoldText,
  StModalContent,
  StModalHeader,
  StModalHeaderIcon,
  StModalHeaderTitle,
} from '../UserInviteDrawer/styled';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';

const UserAlterDrawer = ({ visible, onClose, user }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const { Text } = Typography;
  const breakpoint = useBreakpoint();
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [concilScope, setConcilScope] = useState('0');
  // eslint-disable-next-line no-unused-vars
  const [disableFields, setDisableFields] = useState(false);
  const [concilPermission, setConcilPermission] = useState('1');
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const alterProfileScopeSuccess = useSelector(
    (state) => state.alterProfileScope?.success
  );
  const sendInvitationLoading = useSelector(
    (state) => state.sendInvitation.loading
  );
  const invitationWasSent = useSelector(
    (state) => state.sendInvitation.invitationWasSent
  );
  useEffect(() => {
    form.setFieldsValue({
      name: user.name,
      surname: user.surname,
      email: user.email,
      scope_id: user.scope_id,
      profile_id: user.profile_id,
      phone: user.phone,
    });
    setConcilScope(String(user?.scope_id));
    setConcilPermission(String(user?.profile_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const onFinish = async (values) => {
    const args = {
      params: {
        email: user.email,
        name: values.name,
        surname: values.surname,
        scope_id: values.scope_id,
        profile_id: values.profile_id,
        phone: values.phone,
      },
    };
    setLoadingButton(true);
    dispatch(alterProfileScope(args));
  };

  useEffect(() => {
    if (alterProfileScopeSuccess === true) {
      dispatch(
        listUsersStart({
          scopeId: undefined,
        })
      );
      dispatch(setLoadingProfile(false));
      setLoadingButton(false);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alterProfileScopeSuccess]);

  const onFinishFailed = (errorInfo) =>
    errorHandler(errorInfo?.errorFields[0]?.errors[0]);

  let drawerWidth = '100%';
  if (breakpoint?.md) {
    drawerWidth = '75%';
  }
  if (breakpoint?.lg) {
    drawerWidth = '50%';
  }
  if (breakpoint?.xl) {
    drawerWidth = '50%';
  }
  if (breakpoint?.xxl) {
    drawerWidth = '35%';
  }

  useEffect(() => {
    form.setFieldsValue({
      scope_id: scopes.CLIENTE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkScopeAndProfileBeforeWarning = () => {
    const { scope_id: scopeId, profile_id: profileId } =
      form.getFieldsValue(true);

    return !!(
      (scopeId === scopes.PAGSEGURO && profileId === profiles.ADMINISTRADOR) ||
      (scopeId === scopes.CONCIL &&
        (profileId === profiles.ADMINISTRADOR ||
          profileId === profiles.SUPERADMIN))
    );
  };

  const scopesToBeWarned = {
    3: 'do ConcilCard',
    4: 'PagSeguro',
  };

  const warningModal = (
    <Modal
      open={isModalVisible}
      okText="Sim"
      cancelText="Cancelar"
      closable={false}
      maskClosable={!sendInvitationLoading}
      centered
      onCancel={() => setIsModalVisible(false)}
      onOk={() => form.submit()}
      cancelButtonProps={{
        disabled: sendInvitationLoading,
      }}
      width={400}
      confirmLoading={sendInvitationLoading}
    >
      <StModalBodyWrapper>
        <StModalHeader>
          <StModalHeaderIcon />
          <StModalHeaderTitle>Associar empresas</StModalHeaderTitle>
        </StModalHeader>
        <StModalContent>
          <Text>
            Tem certeza de que deseja associar{' '}
            <StModalBoldText>
              todas as empresas{' '}
              {scopesToBeWarned[form.getFieldValue('scope_id')]}
            </StModalBoldText>{' '}
            para este usuário?
          </Text>
        </StModalContent>
      </StModalBodyWrapper>
    </Modal>
  );

  return (
    <>
      <Drawer
        style={{ position: 'absolute', zIndex: 499 }}
        destroyOnClose
        open={visible}
        onClose={onClose}
        closable={false}
        width={drawerWidth}
        drawerStyle={{
          display: 'absolute',
          paddingTop: '40px',
        }}
        footerStyle={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
        footer={
          <>
            <Button style={{ color: colors.red6 }} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (checkScopeAndProfileBeforeWarning()) {
                  setIsModalVisible(true);
                } else {
                  form.submit();
                }
              }}
              style={{ marginLeft: '1rem' }}
              loading={isLoadingButton}
            >
              Salvar Alterações
            </Button>
          </>
        }
      >
        <StContainer>
          <Typography.Title style={{ color: colors.gray11, fontSize: '3rem' }}>
            Editar permissões
          </Typography.Title>
          <Form
            requiredMark={false}
            form={form}
            layout="vertical"
            style={{
              maxWidth: '100%',
              wordBreak: 'break-all',
              overflowWrap: 'break-word',
            }}
            name="invite_user"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  required
                  name="name"
                  label="Primeiro nome"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório.',
                      min: 1,
                    },
                  ]}
                >
                  <Input placeholder="Insira o nome" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="surname" label="Sobrenome">
                  <Input placeholder="Insira o sobrenome" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item name="email" label="Email">
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="phone"
                  label={
                    <Typography.Text
                      style={{
                        color: colors.gray11,
                        fontSize: '1.75rem',
                      }}
                    >
                      Telefone{' '}
                      <Typography.Text
                        style={{
                          color: colors.gray7,
                          fontSize: '14px',
                        }}
                      >
                        (Opcional)
                      </Typography.Text>
                    </Typography.Text>
                  }
                  style={{ display: 'inline-block', width: '100%' }}
                >
                  <MaskedInput
                    placeholder="Insira o telefone"
                    maskOptions={{
                      mask: '(00) 00000-0000',
                      lazy: true,
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Qual o tipo do novo usuário?"
              name="scope_id"
              rules={[
                { required: true, message: 'Por favor selecione uma opção' },
              ]}
            >
              <Radio.Group>
                {(isClient() && isOperational()) ||
                isManagerial() ||
                isAdmin() ||
                isSuperAdmin() ? (
                  <Radio.Button
                    value={scopes.CLIENTE}
                    onClick={(e) => setConcilScope(e.target.value)}
                    disabled={
                      disableFields ||
                      !isFeatureAvailable({ key: 'invite-scope-cliente' })
                    }
                  >
                    CLIENTE
                  </Radio.Button>
                ) : (
                  <></>
                )}
                {isPagSeguro() || isAdmin() || isSuperAdmin() ? (
                  <Radio.Button
                    value={scopes.PAGSEGURO}
                    onClick={(e) => setConcilScope(e.target.value)}
                    disabled={
                      disableFields ||
                      !isFeatureAvailable({ key: 'invite-scope-pagseguro' })
                    }
                  >
                    PAGSEGURO
                  </Radio.Button>
                ) : (
                  <></>
                )}
                {isPartner() || isAdmin() || isSuperAdmin() ? (
                  <Radio.Button
                    value={scopes.PARCEIRO}
                    onClick={(e) => setConcilScope(e.target.value)}
                    disabled={
                      disableFields ||
                      !isFeatureAvailable({ key: 'invite-scope-parceiro' })
                    }
                  >
                    PARCEIRO
                  </Radio.Button>
                ) : (
                  <></>
                )}
                {isConciler() || isAdmin() || isSuperAdmin() ? (
                  <Radio.Button
                    value={scopes.CONCIL}
                    onClick={(e) => setConcilScope(e.target.value)}
                    disabled={
                      disableFields ||
                      !isFeatureAvailable({ key: 'invite-scope-concil' })
                    }
                  >
                    CONCIL
                  </Radio.Button>
                ) : (
                  <></>
                )}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Qual o nível de permissionamento do conciler ?"
              name="profile_id"
              hidden={concilScope !== '3'}
            >
              <Radio.Group
              // onChange={(e) => {
              //   setConcilPermission(String(e.target.value));
              // }}
              >
                {isConciler() && (isAdmin() || isSuperAdmin()) ? (
                  <Radio
                    style={radioStyle}
                    value={profiles.ADMINISTRADOR}
                    disabled={disableFields}
                  >
                    Administrador: Acessa todas as funcionalidades e convida
                    usuários.
                  </Radio>
                ) : (
                  <></>
                )}
                {isConciler() && isSuperAdmin() ? (
                  <Radio
                    style={radioStyle}
                    value={profiles.SUPERADMIN}
                    disabled={disableFields}
                  >
                    Superadmin: Acessa configurações do sistema, todas as
                    funcionalidades e convida usuários.
                  </Radio>
                ) : (
                  <></>
                )}
              </Radio.Group>
            </Form.Item>
            {isConciler() || isPagSeguro() ? (
              <Form.Item
                label="Qual o nível de permissionamento do usuário PagSeguro ?"
                name="profile_id"
                hidden={concilScope !== '4'}
              >
                <Radio.Group onChange={() => {}}>
                  <Radio
                    style={radioStyle}
                    value={profiles.OPERACIONAL}
                    disabled={disableFields}
                  >
                    Operacional: Acessa funcionalidades básicas e não convida
                    usuários.
                  </Radio>
                  {isAdmin() || isSuperAdmin() ? (
                    <Radio
                      style={radioStyle}
                      value={profiles.ADMINISTRADOR}
                      disabled={disableFields}
                    >
                      Administrador: Acessa todas as funcionalidades e convida
                      usuários.
                    </Radio>
                  ) : (
                    <></>
                  )}
                </Radio.Group>
              </Form.Item>
            ) : (
              <></>
            )}
            <Form.Item
              label="Qual o nível de permissionamento do parceiro ?"
              name="profile_id"
              hidden={concilScope !== '2'}
            >
              <Radio.Group
                value={concilPermission}
                onChange={(e) => {
                  setConcilPermission(e.target.value);
                }}
              >
                <Radio
                  style={radioStyle}
                  value={profiles.GERENCIAL}
                  disabled={disableFields}
                >
                  Gerencial: Acessa funcionalidades completas e convida
                  usuários.
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Qual o nível de permissionamento do cliente ?"
              name="profile_id"
              hidden={concilScope !== '1' && concilScope !== '0'}
            >
              <Radio.Group
                value={concilPermission}
                onChange={(e) => {
                  setConcilPermission(e.target.value);
                }}
              >
                <Radio
                  style={radioStyle}
                  value={profiles.OPERACIONAL}
                  disabled={disableFields}
                >
                  Operacional: Acessa funcionalidades básicas e não convida
                  usuários.
                </Radio>
                <Radio
                  style={radioStyle}
                  value={profiles.GERENCIAL}
                  disabled={disableFields}
                >
                  Gerencial: Acessa funcionalidades completas e convida
                  usuários.
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </StContainer>
      </Drawer>
      {isModalVisible && !invitationWasSent && warningModal}
    </>
  );
};

UserAlterDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};

export default UserAlterDrawer;
