import React, { useState } from 'react';
import { Grid, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { TabsNavigation } from 'components/ManageUsers/components';
import { UserInviteDrawer } from 'commons/Header/components';
import {
  StTitle,
  StClientTitle,
  StContainer,
  StWrapper,
  StLabel,
} from './styled';
import {
  getSessionClientCode,
  getSessionClientName,
} from 'helpers/sessionService';
import { resetCheckInvitationEmail } from 'store/ducks/checkInvitationEmail/actions';

const ManageUsers = () => {
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const client = getSessionClientName();
  const clientCode = getSessionClientCode();

  const [modalVisible, toggleModal] = useState(false);

  const closeUserInviteDrawer = () => {
    toggleModal(false);
    dispatch(resetCheckInvitationEmail({}));
  };

  return (
    <>
      <StContainer $breakpoint={breakpoint}>
        <Row
          align="middle"
          justify="space-between"
          style={{ marginTop: '4rem' }}
        >
          <Col>
            <StTitle>Gerenciar usuários</StTitle>
          </Col>
        </Row>
        <StWrapper>
          <StClientTitle>
            {`${client} ${clientCode !== 'null' ? clientCode : ''}`}
          </StClientTitle>
          <StLabel>
            Visualize e edite os usuários que tem acesso à empresa e suas
            permissões
          </StLabel>

          <TabsNavigation />
        </StWrapper>
        <UserInviteDrawer
          visible={modalVisible}
          onClose={closeUserInviteDrawer}
        />
      </StContainer>
    </>
  );
};

export default ManageUsers;
