import { StContainer, StLimiter } from './styled';
import React from 'react';
import { Col, Row, Typography } from 'antd';
import { TitleAndIcon } from '../../../../commons/TitleAndIcon';
import { InfoCircleFilled } from '@ant-design/icons';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const DemoCard = () => {
  const dataTestPrefix = 'demo-card';

  return (
    <StContainer>
      <StLimiter {...dataTestIdHandler(dataTestPrefix, 'container')}>
        <Row align="middle" justify="space-between">
          <Col>
            <TitleAndIcon titleText="Boas-vindas ao Concil Card!" />
            <br />
            <div style={{ marginBottom: '1rem' }}>
              <Typography.Text
                style={{ fontSize: 14 }}
                {...dataTestIdHandler(dataTestPrefix, 'stage-message')}
              >
                Essa notificação indica que sua implantação está em{' '}
                <strong>andamento</strong>.
              </Typography.Text>
            </div>
            <Typography.Text style={{ fontSize: 14 }}>
              Enquanto isso, você já pode fazer seu treinamento online. Clique
              na assistente virtual no canto inferior esquerdo da sua tela para
              aprender a usar o Concil Card e passe o mouse pelo símbolo “
              <InfoCircleFilled style={{ color: colors.primary6 }} />” para ter
              dicas rápidas.
            </Typography.Text>
            <Row>
              <Col md={10} lg={8} />
              <Col md={10} lg={8} />
              <Col md={10} lg={8} />
            </Row>
          </Col>
        </Row>
      </StLimiter>
    </StContainer>
  );
};

export default DemoCard;
