export const actionTypes = {
  CHECK_INVITATION_EMAIL_START:
    '@checkInvitationEmail/CHECK_INVITATION_EMAIL_START',
  CHECK_INVITATION_EMAIL_SUCCESS:
    '@checkInvitationEmail/CHECK_INVITATION_EMAIL_SUCCESS',
  CHECK_INVITATION_EMAIL_ERROR:
    '@checkInvitationEmail/CHECK_INVITATION_EMAIL_ERROR',
  RESET_CHECK_INVITATION_EMAIL:
    '@checkInvitationEmail/RESET_CHECK_INVITATION_EMAIL',
};
