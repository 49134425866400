import React, { useContext } from 'react';
import { Col, Input, Row, Typography } from 'antd';
import { isConciler, isSuperAdmin } from 'helpers/authorizationService';
import { StButton2, StTitle } from 'components/BranchManagement/styled';
import { StCol, StColWrapper } from './styled';
import { BranchManagementContext } from 'components/BranchManagement/BranchManagement';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const BranchManagementHeader = () => {
  const { Text } = Typography;
  const { Search } = Input;
  const { getListOfClients, searchText, setSearchText, onClick } = useContext(
    BranchManagementContext
  );

  return (
    <Row align="middle" justify="space-between">
      <Col>
        <StTitle>Gerenciar empresas</StTitle>
        <Text>Consulte ou edite as informações cadastrais de uma empresa</Text>
      </Col>

      <StColWrapper>
        <StCol>
          <Search
            placeholder="Buscar empresas"
            value={searchText}
            onChange={(e) => setSearchText(e?.target?.value)}
            onSearch={getListOfClients}
            style={{ width: 350 }}
          />
          {isConciler() && isSuperAdmin() && (
            <StButton2
              type="primary"
              onClick={onClick}
              {...dataTestIdHandler('branch-management', 'create-company')}
            >
              Criar empresa
            </StButton2>
          )}
        </StCol>
      </StColWrapper>
    </Row>
  );
};

export default BranchManagementHeader;
