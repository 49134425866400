import React from 'react';
import { Row, Col, Typography } from 'antd';
import { StDivider, StTotalText } from './styled';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const ReconciliationHeader = ({
  leftColReconciledText,
  leftColReconciledAmount,
  leftColPendentAmount,
  rightColReconciledText,
  rightColReconciledAmount,
  rightColPendentAmount,
  reconciliationType,
}) => {
  const dataTestPrefix = 'reconciliation-header';
  const parseValue = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <Col span={reconciliationType !== 'bank' ? 12 : 4}>
      <Row justify="space-between" align="middle">
        {/* COLUNA DA ESQUERDA */}
        <Col span={12}>
          <Row align="center">
            <Typography.Text
              style={{ color: colors.gray9 }}
              {...dataTestIdHandler(dataTestPrefix, 'left-col-reconciled-text')}
            >
              {leftColReconciledText}
            </Typography.Text>
          </Row>
          <Row
            align="middle"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {reconciliationType !== 'bank' ? (
              <>
                <StTotalText>
                  {`Total conciliado: ${parseValue(leftColReconciledAmount)}`}
                </StTotalText>

                <StDivider type="vertical" />

                <StTotalText>
                  {`Total não conciliado: ${parseValue(leftColPendentAmount)}`}
                </StTotalText>
              </>
            ) : (
              <StTotalText
                {...dataTestIdHandler(
                  dataTestPrefix,
                  'left-col-reconciled-value'
                )}
              >
                {parseValue(leftColReconciledAmount)}
              </StTotalText>
            )}
          </Row>
        </Col>

        {/* COLUNA DA DIREITA */}
        <Col span={12}>
          <Row align="center">
            <Typography.Text
              style={{ color: colors.gray9 }}
              {...dataTestIdHandler(
                dataTestPrefix,
                'right-col-reconciled-text'
              )}
            >
              {rightColReconciledText}
            </Typography.Text>
          </Row>
          <Row
            align="middle"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {reconciliationType !== 'bank' ? (
              <>
                <StTotalText>
                  {`Total conciliado: ${parseValue(rightColReconciledAmount)}`}
                </StTotalText>

                <StDivider type="vertical" />

                <StTotalText>
                  {`Total não conciliado: ${parseValue(rightColPendentAmount)}`}
                </StTotalText>
              </>
            ) : (
              <StTotalText
                {...dataTestIdHandler(
                  dataTestPrefix,
                  'right-col-reconciled-value'
                )}
              >
                {parseValue(rightColReconciledAmount)}
              </StTotalText>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ReconciliationHeader;
