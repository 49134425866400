import React, { useContext } from 'react';
import { OnboardingManagementContext } from 'components/OnboardingManagement/OnboardingManagement';
import { Select } from 'antd';
import { disableLastThreeMonths } from 'helpers/disableDates';
import * as St from './styled';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export function Filters() {
  const {
    form,
    rules,
    stageOptions,
    initialValues,
    resetForm,
    loading,
    changeCalendarIconColor,
    onFinish,
    plans,
    status,
  } = useContext(OnboardingManagementContext);
  const { Option } = Select;
  const dataTestPrefix = 'onboarding-management';

  return (
    <St.Container>
      <St.Title {...dataTestIdHandler(dataTestPrefix, 'title')}>
        Gerenciar implantação
      </St.Title>
      <St.Subtitle>
        Utilize os filtros para realizar pesquisas avançadas
      </St.Subtitle>

      <St.Form
        form={form}
        disabled={loading}
        initialValues={initialValues}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <St.FormItemContainer gap={8}>
          <div>
            {changeCalendarIconColor && <St.RequiredMark>*</St.RequiredMark>}
            <St.Label>Data criação da empresa</St.Label>
          </div>
          <St.FormItem
            name="date"
            $noMargin
            validateTrigger={['onBlur', 'onSubmit']}
            rules={[rules.date]}
          >
            <St.RangePicker
              width={240}
              format="DD/MM/YYYY"
              placeholder={['Selecionar', 'Selecionar']}
              changeCalendarIconColor={changeCalendarIconColor}
              onCalendarChange={(values) => {
                form.setFieldValue('date', values);
              }}
              disabledDate={(current) => disableLastThreeMonths(current)}
              {...dataTestIdHandler('filters', 'range-picker')}
            />
          </St.FormItem>
        </St.FormItemContainer>

        <St.FormItemContainer>
          <St.Label>PFJ</St.Label>
          <St.FormItem
            name="codClient"
            hasFeedback
            validateTrigger={['onSubmit']}
            rules={[rules.codClient]}
          >
            <St.Input placeholder="Digitar" />
          </St.FormItem>
        </St.FormItemContainer>

        <St.FormItemContainer>
          <St.Label>Estágio</St.Label>
          <St.FormItem name="stage">
            <St.Select placeholder="Selecione" allowClear>
              {stageOptions?.map(({ id, value, label }) => {
                return (
                  <Option value={value} key={id}>
                    {label}
                  </Option>
                );
              })}
            </St.Select>
          </St.FormItem>
        </St.FormItemContainer>

        <St.FormItemContainer>
          <St.Label>Plano</St.Label>
          <St.FormItem name="plan">
            <St.Select placeholder="Selecione" allowClear>
              {plans?.map(({ name, plan_id: planId }) => {
                return (
                  <Option value={name?.toUpperCase()} key={`plan-${planId}`}>
                    {name}
                  </Option>
                );
              })}
            </St.Select>
          </St.FormItem>
        </St.FormItemContainer>

        <St.FormItemContainer>
          <St.Label>Status de validação</St.Label>
          <St.FormItem name="status">
            <St.Select placeholder="Selecione" allowClear>
              {status?.map(({ statusId, value }) => {
                return (
                  <Option value={value} key={statusId}>
                    {value}
                  </Option>
                );
              })}
            </St.Select>
          </St.FormItem>
        </St.FormItemContainer>

        <St.ButtonsContainer>
          <St.Button
            type="primary"
            htmlType="submit"
            width={91}
            {...dataTestIdHandler(dataTestPrefix, 'filter-submit')}
          >
            Filtrar
          </St.Button>
          <St.Button htmlType="submit" width={123} onClick={resetForm}>
            Limpar filtros
          </St.Button>
        </St.ButtonsContainer>
      </St.Form>
    </St.Container>
  );
}
