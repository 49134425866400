import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { StTableColumnTitle } from 'commons/Table/styled';
import BranchStage from '../BranchStage';
import BranchActions from '../BranchActions';
import {
  onColumnFilter,
  columnFilters,
  getDefaultColumns,
} from 'helpers/generalService';
import {
  tokenSandboxGetStart,
  rowClientIdSandboxStart,
} from 'store/ducks/tokenSandbox/actions';
import { tokenProdGetStart } from 'store/ducks/tokenProd/actions';
import { StTable } from './styled';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import {
  listOfClientsFiltersStart,
  listOfClientsFiltersZeroLength,
} from 'store/ducks/filters/listOfClientsFilters/actions';
import store from 'store';
import { BranchManagementContext } from 'components/BranchManagement/BranchManagement';
import FilterDropdown from 'commons/Table/components/FilterDropdown';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import { FilterFilled } from '@ant-design/icons';

const BranchTable = () => {
  const dataTestPrefix = 'branch-management';
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const { Text } = Typography;
  const { width } = useWindowSize();
  const smallScreen = width <= 1366; // 1366 = Prototype's width value
  let filteredColumns = useSelector(
    (state) => state?.updateFilteredColumns?.filteredColumns
  );

  const {
    filteredListOfClients,
    listOfClientsLoading,
    visible,
    setVisible,
    setBranchData,
    pageTotal,
    page,
    perPage,
    getListOfClients,
    closeDrawer,
    handleTableChange,
    toParamsFilters,
    filtersData: apiFilters,
  } = useContext(BranchManagementContext);

  const showDrawer = (_rowClientId) => {
    dispatch(rowClientIdSandboxStart({ value: _rowClientId }));
    setVisible(true);
    dispatch(
      tokenSandboxGetStart({
        type: 'SANDBOX',
        clientId: _rowClientId,
        hidden: false,
      })
    );
    dispatch(
      tokenProdGetStart({
        type: 'PRODUCTION',
        clientId: _rowClientId,
        hidden: false,
      })
    );
  };

  useEffect(() => {
    const transformedDataSource = [];
    if (filteredListOfClients !== undefined) {
      filteredListOfClients.forEach((client, index) => {
        const {
          client_code_name: clientCodeName,
          is_implanted: isImplanted,
          created_at: createdAt,
          is_api_connected: isApiConnected,
          is_sandbox_api_active: isSandboxApiActive,
          is_production_api_active: isProductionApiActive,
        } = client;
        transformedDataSource.push({
          key: index,
          client_code_name: clientCodeName,
          is_implanted: isImplanted,
          created_at: createdAt,
          actions: client,
          is_api_connected: isApiConnected,
          is_sandbox_api_active: isSandboxApiActive,
          is_production_api_active: isProductionApiActive,
        });
      });

      setDataSource(transformedDataSource);
    }
  }, [filteredListOfClients]);

  const columns = [
    {
      title: () => {
        return (
          <StTableColumnTitle
            fontSize="14px"
            fontWeight={500}
            {...dataTestIdHandler(dataTestPrefix, 'column-client_code_name')}
          >
            Código e nome da empresa
          </StTableColumnTitle>
        );
      },
      dataIndex: 'client_code_name',
      filteredValue: getDefaultColumns(toParamsFilters, 'client_code_name'),
      filters:
        apiFilters.length > 0
          ? columnFilters(apiFilters, 'client_code_name')
          : [],
      onFilter: (value, record) =>
        onColumnFilter(value, record, 'client_code_name'),
      onFilterDropdownVisibleChange: (_visible) => {
        filteredColumns =
          store.getState().updateFilteredColumns?.filteredColumns;
        if (_visible) {
          store.dispatch(listOfClientsFiltersZeroLength());
          store.dispatch(
            listOfClientsFiltersStart({
              columnName: 'client_code_name',
              offset: 0,
              q:
                filteredColumns.length !== 0
                  ? {
                      filter: filteredColumns,
                    }
                  : [],
            })
          );
        }
      },
      filterIcon: () => (
        <FilterFilled
          {...dataTestIdHandler(dataTestPrefix, 'filter-icon-client_code_name')}
        />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <FilterDropdown
            key={filteredColumns}
            apiFilters={apiFilters}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            filterAction={listOfClientsFiltersStart}
            filteredColumns={
              store.getState().updateFilteredColumns?.filteredColumns
            }
            clearAction={listOfClientsFiltersZeroLength}
            columnName="client_code_name"
            storeName="listOfClientsFilters"
          />
        );
      },
      filterMultiple: true,
      sorter: {
        multiple: 1,
      },
      width: 374,
    },
    {
      title: () => {
        return (
          <StTableColumnTitle
            fontSize="14px"
            fontWeight={500}
            {...dataTestIdHandler(dataTestPrefix, 'column-is_implanted')}
          >
            Estágio
          </StTableColumnTitle>
        );
      },
      dataIndex: 'is_implanted',
      filteredValue: getDefaultColumns(toParamsFilters, 'is_implanted'),
      filters:
        apiFilters.length > 0 ? columnFilters(apiFilters, 'is_implanted') : [],
      onFilter: (value, record) =>
        onColumnFilter(value, record, 'is_implanted'),
      onFilterDropdownVisibleChange: (_visible) => {
        filteredColumns =
          store.getState().updateFilteredColumns?.filteredColumns;
        if (_visible) {
          store.dispatch(listOfClientsFiltersZeroLength());
          store.dispatch(
            listOfClientsFiltersStart({
              columnName: 'is_implanted',
              offset: 0,
              q:
                filteredColumns.length !== 0
                  ? {
                      filter: filteredColumns,
                    }
                  : [],
            })
          );
        }
      },
      filterIcon: () => (
        <FilterFilled
          {...dataTestIdHandler(dataTestPrefix, 'filter-icon-is_implanted')}
        />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <FilterDropdown
            key={filteredColumns}
            apiFilters={apiFilters}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            filterAction={listOfClientsFiltersStart}
            filteredColumns={
              store.getState().updateFilteredColumns?.filteredColumns
            }
            clearAction={listOfClientsFiltersZeroLength}
            columnName="is_implanted"
            storeName="listOfClientsFilters"
          />
        );
      },
      filterMultiple: true,
      sorter: {
        multiple: 1,
      },
      render: (isImplanted) => {
        return <BranchStage isImplanted={isImplanted} />;
      },
      width: smallScreen && 120,
    },
    {
      title: () => {
        return (
          <StTableColumnTitle
            fontSize="14px"
            fontWeight={500}
            {...dataTestIdHandler(dataTestPrefix, 'column-created_at')}
          >
            Data de criação
          </StTableColumnTitle>
        );
      },
      dataIndex: 'created_at',
      filteredValue: getDefaultColumns(toParamsFilters, 'created_at'),
      filters:
        apiFilters.length > 0 ? columnFilters(apiFilters, 'created_at') : [],
      onFilter: (value, record) => onColumnFilter(value, record, 'created_at'),
      onFilterDropdownVisibleChange: (_visible) => {
        filteredColumns =
          store.getState().updateFilteredColumns?.filteredColumns;
        if (_visible) {
          store.dispatch(listOfClientsFiltersZeroLength());
          store.dispatch(
            listOfClientsFiltersStart({
              columnName: 'created_at',
              offset: 0,
              q:
                filteredColumns.length !== 0
                  ? {
                      filter: filteredColumns,
                    }
                  : [],
            })
          );
        }
      },
      filterIcon: () => (
        <FilterFilled
          {...dataTestIdHandler(dataTestPrefix, 'filter-icon-created_at')}
        />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <FilterDropdown
            key={filteredColumns}
            apiFilters={apiFilters}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            filterAction={listOfClientsFiltersStart}
            filteredColumns={
              store.getState().updateFilteredColumns?.filteredColumns
            }
            clearAction={listOfClientsFiltersZeroLength}
            columnName="created_at"
            storeName="listOfClientsFilters"
          />
        );
      },
      filterMultiple: true,
      sorter: {
        multiple: 1,
      },
      width: smallScreen && 187,
    },
    {
      title: () => {
        return (
          <StTableColumnTitle
            fontSize="14px"
            fontWeight={500}
            {...dataTestIdHandler(dataTestPrefix, 'column-is_api_connected')}
          >
            API
          </StTableColumnTitle>
        );
      },
      dataIndex: 'is_api_connected',
      filteredValue: getDefaultColumns(toParamsFilters, 'is_api_connected'),
      filters:
        apiFilters.length > 0
          ? columnFilters(apiFilters, 'is_api_connected')
          : [],
      onFilter: (value, record) =>
        onColumnFilter(value, record, 'is_api_connected'),
      onFilterDropdownVisibleChange: (_visible) => {
        filteredColumns =
          store.getState().updateFilteredColumns?.filteredColumns;
        if (_visible) {
          store.dispatch(listOfClientsFiltersZeroLength());
          store.dispatch(
            listOfClientsFiltersStart({
              columnName: 'is_api_connected',
              offset: 0,
              q:
                filteredColumns.length !== 0
                  ? {
                      filter: filteredColumns,
                    }
                  : [],
            })
          );
        }
      },
      filterIcon: () => (
        <FilterFilled
          {...dataTestIdHandler(dataTestPrefix, 'filter-icon-is_api_connected')}
        />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <FilterDropdown
            key={filteredColumns}
            apiFilters={apiFilters}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            filterAction={listOfClientsFiltersStart}
            filteredColumns={
              store.getState().updateFilteredColumns?.filteredColumns
            }
            clearAction={listOfClientsFiltersZeroLength}
            columnName="is_api_connected"
            storeName="listOfClientsFilters"
          />
        );
      },
      filterMultiple: true,
      sorter: {
        multiple: 1,
      },
      width: smallScreen && 100,
      render: (text) => {
        return <Text>{text}</Text>;
      },
    },
    {
      title: () => {
        return (
          <StTableColumnTitle
            fontSize="14px"
            fontWeight={500}
            {...dataTestIdHandler(
              dataTestPrefix,
              'column-is_sandbox_api_active'
            )}
          >
            Sandbox
          </StTableColumnTitle>
        );
      },
      dataIndex: 'is_sandbox_api_active',
      filteredValue: getDefaultColumns(
        toParamsFilters,
        'is_sandbox_api_active'
      ),
      filters:
        apiFilters.length > 0
          ? columnFilters(apiFilters, 'is_sandbox_api_active')
          : [],
      onFilter: (value, record) =>
        onColumnFilter(value, record, 'is_sandbox_api_active'),
      onFilterDropdownVisibleChange: (_visible) => {
        filteredColumns =
          store.getState().updateFilteredColumns?.filteredColumns;
        if (_visible) {
          store.dispatch(listOfClientsFiltersZeroLength());
          store.dispatch(
            listOfClientsFiltersStart({
              columnName: 'is_sandbox_api_active',
              offset: 0,
              q:
                filteredColumns.length !== 0
                  ? {
                      filter: filteredColumns,
                    }
                  : [],
            })
          );
        }
      },
      filterIcon: () => (
        <FilterFilled
          {...dataTestIdHandler(
            dataTestPrefix,
            'filter-icon-is_sandbox_api_active'
          )}
        />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <FilterDropdown
            key={filteredColumns}
            apiFilters={apiFilters}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            filterAction={listOfClientsFiltersStart}
            filteredColumns={
              store.getState().updateFilteredColumns?.filteredColumns
            }
            clearAction={listOfClientsFiltersZeroLength}
            columnName="is_sandbox_api_active"
            storeName="listOfClientsFilters"
          />
        );
      },
      filterMultiple: true,
      sorter: {
        multiple: 1,
      },
      width: smallScreen && 131,
      render: (text) => {
        return <Text>{text}</Text>;
      },
    },
    {
      title: () => {
        return (
          <StTableColumnTitle
            fontSize="14px"
            fontWeight={500}
            {...dataTestIdHandler(
              dataTestPrefix,
              'column-is_production_api_active'
            )}
          >
            Produção
          </StTableColumnTitle>
        );
      },
      dataIndex: 'is_production_api_active',
      filteredValue: getDefaultColumns(
        toParamsFilters,
        'is_production_api_active'
      ),
      filters:
        apiFilters.length > 0
          ? columnFilters(apiFilters, 'is_production_api_active')
          : [],
      onFilter: (value, record) =>
        onColumnFilter(value, record, 'is_production_api_active'),
      onFilterDropdownVisibleChange: (_visible) => {
        filteredColumns =
          store.getState().updateFilteredColumns?.filteredColumns;
        if (_visible) {
          store.dispatch(listOfClientsFiltersZeroLength());
          store.dispatch(
            listOfClientsFiltersStart({
              columnName: 'is_production_api_active',
              offset: 0,
              q:
                filteredColumns.length !== 0
                  ? {
                      filter: filteredColumns,
                    }
                  : [],
            })
          );
        }
      },
      filterIcon: () => (
        <FilterFilled
          {...dataTestIdHandler(
            dataTestPrefix,
            'filter-icon-is_production_api_active'
          )}
        />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <FilterDropdown
            key={filteredColumns}
            apiFilters={apiFilters}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            filterAction={listOfClientsFiltersStart}
            filteredColumns={
              store.getState().updateFilteredColumns?.filteredColumns
            }
            clearAction={listOfClientsFiltersZeroLength}
            columnName="is_production_api_active"
            storeName="listOfClientsFilters"
          />
        );
      },
      filterMultiple: true,
      sorter: {
        multiple: 1,
      },
      width: smallScreen && 131,
      render: (text) => {
        return <Text>{text}</Text>;
      },
    },
    {
      title: () => {
        return (
          <StTableColumnTitle
            fontSize="14px"
            fontWeight={500}
            {...dataTestIdHandler(dataTestPrefix, 'column-actions')}
          >
            Ações
          </StTableColumnTitle>
        );
      },
      dataIndex: 'actions',
      render: (_branchData) => {
        const { client_id: clientId } = _branchData;
        return (
          <BranchActions
            rowClientId={clientId}
            branchData={_branchData}
            setBranchData={setBranchData}
            visible={visible}
            showDrawer={showDrawer}
            closeDrawer={closeDrawer}
            getListOfClients={getListOfClients}
          />
        );
      },
      // width: 250,
    },
  ];

  return (
    <>
      <StTable
        dataSource={dataSource}
        columns={columns}
        loading={listOfClientsLoading}
        bordered
        rowClassName="table-row-line"
        onChange={handleTableChange}
        pagination={{
          current: page,
          pageSize: perPage,
          total: pageTotal,
          pageSizeOptions: perPageOptions,
          showTotal: showTotalPage,
          showSizeChanger: Boolean(dataSource?.length >= 10),
        }}
        scroll={{
          x: '100%',
          y: window.innerHeight - 400,
        }}
        {...dataTestIdHandler(dataTestPrefix, 'table')}
      />
    </>
  );
};

export default BranchTable;
