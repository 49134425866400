/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, Row, Spin, Tooltip, Typography } from 'antd';
import { CopyOutlined, WarningOutlined } from '@ant-design/icons';
import {
  tokenProdDeleteStart,
  tokenProdPostStart,
  tokenProdPutStart,
} from 'store/ducks/tokenProd/actions';
import { copyAppKey } from '../../utils/copyAppKey';
import { handleStatus } from '../../utils/handleStatus';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

export const TokenProdCard = ({
  prodRequestDeleteAt,
  prodDeleteLoading,
  prodPostLoading,
  prodIsActive,
  rowClientId,
  prodGetLoading,
  prodAppKey,
  setUserHasCopied,
  prodPutLoading,
  prodEndDate,
  today,
  isApiConnected,
  updateBranchDataLoading,
  getListOfClients,
}) => {
  const dataTestPrefix = 'branch-management-token-prod';
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [preDisable, setPreDisable] = useState(false);

  const tokenProdHasBeenPosted = useSelector(
    (state) => state.tokenProd.tokenProdHasBeenPosted
  );
  const tokenProdHasBeenDeleted = useSelector(
    (state) => state.tokenProd.tokenProdHasBeenDeleted
  );
  const tokenProdLoadingDelete = useSelector(
    (state) => state.tokenProd.loadingDelete
  );

  useEffect(() => {
    if (tokenProdHasBeenDeleted && preDisable) {
      setPreDisable(false);
      getListOfClients();
    }

    if (tokenProdHasBeenPosted) {
      getListOfClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenProdHasBeenDeleted, tokenProdHasBeenPosted]);

  return (
    <Card
      headStyle={{
        backgroundColor: preDisable && colors.gold1,
        display: preDisable && 'flex',
      }}
      title={
        !preDisable ? (
          'Produção'
        ) : (
          <div
            style={{
              width: '100%',
              backgroundColor: colors.gold1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <WarningOutlined
              style={{ color: colors.gold6, fontSize: '21px' }}
            />
            <Text
              style={{
                fontSize: '14px',
                fontWeight: '400',
                marginLeft: '17.5px',
              }}
            >
              Tem certeza que deseja desativar este ambiente?
            </Text>
            <Button
              type="link"
              style={{
                color: colors.primary6,
                fontSize: '14px',
              }}
              disabled={tokenProdLoadingDelete}
              onClick={() => {
                setPreDisable(false);
              }}
            >
              Voltar
            </Button>
            {!prodDeleteLoading && !updateBranchDataLoading ? (
              <Button
                type="link"
                style={{ color: colors.red7 }}
                onClick={() => {
                  if (prodIsActive && preDisable) {
                    dispatch(
                      tokenProdDeleteStart({
                        clientId: rowClientId,
                        type: 'PRODUCTION',
                      })
                    );
                  }
                }}
              >
                Desativar
              </Button>
            ) : (
              <Spin />
            )}
          </div>
        )
      }
      extra={
        preDisable ? (
          <></>
        ) : !prodGetLoading &&
          !preDisable &&
          !prodPostLoading &&
          !updateBranchDataLoading ? (
          // eslint-disable-next-line react/jsx-indent
          <Text
            style={{
              color:
                prodIsActive && isApiConnected
                  ? colors.red7
                  : !prodIsActive && isApiConnected
                    ? colors.primary6
                    : !isApiConnected && '#bdbebe',
              cursor: isApiConnected ? 'pointer' : 'not-allowed',
            }}
            onClick={() => {
              if (isApiConnected && prodIsActive && !preDisable) {
                setPreDisable(true);
              }
              if (isApiConnected && !prodIsActive && !preDisable) {
                dispatch(
                  tokenProdPostStart({
                    clientId: rowClientId,
                    type: 'PRODUCTION',
                  })
                );
              }
            }}
            {...dataTestIdHandler(dataTestPrefix, 'handle-action')}
          >
            {prodIsActive && 'Desativar'}
            {!prodIsActive &&
              (prodRequestDeleteAt === '' || prodRequestDeleteAt === null) &&
              'Ativar'}
            {!prodIsActive &&
              prodRequestDeleteAt !== '' &&
              prodRequestDeleteAt !== null &&
              prodRequestDeleteAt.startsWith('20') &&
              'Reativar'}
          </Text>
        ) : (
          <Spin />
        )
      }
      style={{ width: '100%' }}
      bodyStyle={{
        // prettier-ignore
        padding: (!prodIsActive && prodRequestDeleteAt === null) || (!prodIsActive && prodRequestDeleteAt !== null && !prodRequestDeleteAt.startsWith('20')) ? 0 : (!prodIsActive && prodRequestDeleteAt.startsWith('20')) && '24px',
      }}
    >
      {(prodIsActive && !prodGetLoading) ||
      (prodIsActive && prodRequestDeleteAt === '') ||
      (!prodIsActive &&
        prodRequestDeleteAt !== '' &&
        prodRequestDeleteAt !== null) ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <Row>
            <Text style={{ color: colors.gray8, fontSize: '12px' }}>
              Status
            </Text>
          </Row>
          <Row style={{ marginBottom: '16px' }}>
            <Text
              style={{
                color: colors.gray8,
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              {handleStatus(
                prodIsActive,
                prodRequestDeleteAt,
                prodEndDate,
                today
              )}
            </Text>
          </Row>

          <Row>
            <Text style={{ color: colors.gray8, fontSize: '14px' }}>Token</Text>
          </Row>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ display: 'flex', width: 'calc(100% - 110px)' }}
              {...dataTestIdHandler(dataTestPrefix, 'app-key-container')}
            >
              <Input
                value={prodAppKey}
                suffix={
                  <Tooltip title="Clique para copiar">
                    <CopyOutlined
                      style={{ color: colors.primary6 }}
                      onClick={() => copyAppKey(prodAppKey, setUserHasCopied)}
                      disabled={updateBranchDataLoading}
                      {...dataTestIdHandler(dataTestPrefix, 'copy-app-key')}
                    />
                  </Tooltip>
                }
                disabled={prodPutLoading}
                {...dataTestIdHandler(dataTestPrefix, 'app-key')}
              />
            </div>
            <div>
              {!prodPutLoading && !updateBranchDataLoading ? (
                <Button
                  style={{
                    color:
                      !prodIsActive && prodRequestDeleteAt !== ''
                        ? colors.gray8
                        : colors.primary6,
                    fontSize: '14px',
                  }}
                  type="link"
                  disabled={
                    prodPutLoading ||
                    (!prodIsActive && prodRequestDeleteAt !== '')
                  }
                  onClick={() =>
                    dispatch(
                      tokenProdPutStart({
                        type: 'PRODUCTION',
                        clientId: rowClientId,
                      })
                    )
                  }
                  {...dataTestIdHandler(dataTestPrefix, 'generate-new-app-key')}
                >
                  Gerar novo
                </Button>
              ) : (
                <Spin style={{ marginRight: '30px', marginTop: '5px' }} />
              )}
            </div>
          </Row>
        </>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default TokenProdCard;
