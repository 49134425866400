import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, notification, Modal } from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { StActionCol, StActionLink } from 'components/BranchManagement/styled';
import removeBranch from 'services/removeBranch';
import { isConciler, isSuperAdmin } from 'helpers/authorizationService';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const BranchActions = ({
  rowClientId,
  branchData,
  setBranchData,
  visible,
  showDrawer,
  closeDrawer,
  getListOfClients,
}) => {
  const handleRemoveBranch = () => {
    Modal.confirm({
      autoFocusButton: 'cancel',
      title: 'Tem certeza que deseja remover esta empresa?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação não poderá ser desfeita.',
      okText: 'Remover',
      cancelText: 'Cancelar',
      width: '50.875rem',
      centered: true,
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        try {
          await removeBranch({
            clientId: rowClientId,
          });
          notification.success({
            message: 'Empresa removida com sucesso!',
          });
          return getListOfClients();
        } catch (e) {
          return notification.error({
            message:
              e?.response?.data?.data?.message || 'Erro ao remover empresa.',
          });
        }
      },
    });
  };

  const openDetails = () => {
    if (visible) {
      closeDrawer();
    } else {
      showDrawer(rowClientId);
      setBranchData(branchData);
    }
  };

  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <StActionCol>
        <StActionLink
          onClick={openDetails}
          {...dataTestIdHandler('branch-management', 'setting-button')}
        >
          <SettingOutlined style={{ color: colors.primary6 }} />
        </StActionLink>
      </StActionCol>
      <Col style={{ marginLeft: '34px' }}>
        {isConciler() && isSuperAdmin() ? (
          <StActionLink onClick={handleRemoveBranch}>
            <DeleteOutlined style={{ color: colors.red5 }} />
          </StActionLink>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

BranchActions.propTypes = {
  branchData: PropTypes.shape({
    client_id: PropTypes.number,
    client_code: PropTypes.string,
    name: PropTypes.string,
    short_name: PropTypes.string,
    br_cnpj: PropTypes.string,
    br_im: PropTypes.string,
    br_ie: PropTypes.string,
    address_id: PropTypes.number,
    is_implanted: PropTypes.string,
    skin_id: PropTypes.number,
    plan_id: PropTypes.number,
    organization: PropTypes.string,
    is_api_connected: PropTypes.string,
    created_at: PropTypes.string,
  }),
  setBranchData: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  showDrawer: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

BranchActions.defaultProps = {
  branchData: {
    client_id: 0,
    client_code: '',
    name: '',
    short_name: '',
    br_cnpj: '',
    br_im: '',
    br_ie: '',
    address_id: 0,
    is_implanted: '',
    skin_id: 0,
    plan_id: 0,
    organization: '',
    is_api_connected: '',
    created_at: '',
  },
};

export default BranchActions;
