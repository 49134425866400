import React, { useState, useEffect } from 'react';
import { Row, Col, Skeleton, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import customHistory from 'helpers/history';
import { amountsReceivableSummaryStart } from 'store/ducks/amountsReceivableSummary/actions';
import { LabelAndValue } from 'commons/LabelAndValue';
import { TitleAndIcon } from 'commons/TitleAndIcon';
import { MonthlyBarChart } from 'commons/MonthlyBarChart';
import { useDispatch, useSelector } from 'react-redux';
import CardContainer from 'commons/CardContainer';
import {
  StDashboardTag,
  StDropdownContent,
  StHoverClickTitle,
  StHoverClickValue,
} from 'components/Dashboard/styled';
import { CalendarOutlined } from '@ant-design/icons';
import { updateScreenToScreenDateStart } from 'store/ducks/updateScreenToScreenDate/actions';
import { updateScreenToScreenAmountsReceivablePeriodStart } from 'store/ducks/updateScreenToScreenPeriod/actions';
import {
  currentDayPlusOne,
  eighteenMonthsAheadOfCurrentDayPlusOne,
  endOfCurrentYear,
  endOfNextYear,
  startOfNextYear,
} from 'helpers/dates';
import dayjs from 'dayjs';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const amountsReceivableIconUri = `${assetsBaseURL}/svg/amountsReceivableIcon.svg`;

const AmountsReceivableCard = ({
  startDateAmountsReceivable,
  endDateAmountsReceivable,
  setStartDateAmountsReceivable,
  setEndDateAmountsReceivable,
  ...rest
}) => {
  const dispatch = useDispatch();
  const dropdownKeyValue = useSelector(
    (store) => store.updateScreenToScreenPeriod?.amountsReceivablePeriod
  );
  const dataTestPrefix = 'amounts-receivable-card';

  useEffect(() => {
    switch (dropdownKeyValue) {
      case 'proximos_18_meses':
        dispatch(
          amountsReceivableSummaryStart({
            startDate: currentDayPlusOne,
            endDate: eighteenMonthsAheadOfCurrentDayPlusOne,
          })
        );
        break;
      case 'este_ano':
        dispatch(
          amountsReceivableSummaryStart({
            startDate: currentDayPlusOne,
            endDate: endOfCurrentYear,
          })
        );
        break;
      case 'proximo_ano':
        dispatch(
          amountsReceivableSummaryStart({
            startDate: startOfNextYear,
            endDate: endOfNextYear,
          })
        );
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, startDateAmountsReceivable, endDateAmountsReceivable]);

  const [totalNetValue, setTotalNetValue] = useState(0);
  const [forecast, setForecast] = useState([]);

  const amountsReceivableSummary = useSelector(
    (state) => state.amountsReceivableSummary.summary
  );
  const amountsReceivableSummaryLoading = useSelector(
    (state) => state.amountsReceivableSummary.loading
  );

  useEffect(() => {
    setTotalNetValue(
      new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(0)
    );
    if (amountsReceivableSummary !== undefined) {
      if (amountsReceivableSummary.total_net_value !== undefined) {
        setTotalNetValue(
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(amountsReceivableSummary.total_net_value)
        );
      }
      if (amountsReceivableSummary.forecast !== undefined) {
        setForecast(amountsReceivableSummary.forecast);
      }
    }
  }, [amountsReceivableSummary]);

  const goToAmountsReceivable = () => {
    customHistory.push('/valores-a-receber');
    dispatch(
      updateScreenToScreenDateStart({
        startDate: startDateAmountsReceivable,
        endDate: endDateAmountsReceivable,
      })
    );
  };

  const handleDropdownMenu = (key) => {
    switch (key) {
      case 'proximos_18_meses':
        setStartDateAmountsReceivable(currentDayPlusOne);
        setEndDateAmountsReceivable(eighteenMonthsAheadOfCurrentDayPlusOne);
        dispatch(
          updateScreenToScreenAmountsReceivablePeriodStart('proximos_18_meses')
        );
        break;
      case 'este_ano':
        setStartDateAmountsReceivable(currentDayPlusOne);
        setEndDateAmountsReceivable(endOfCurrentYear);
        dispatch(updateScreenToScreenAmountsReceivablePeriodStart('este_ano'));
        break;
      case 'proximo_ano':
        setStartDateAmountsReceivable(startOfNextYear);
        setEndDateAmountsReceivable(endOfNextYear);
        dispatch(
          updateScreenToScreenAmountsReceivablePeriodStart('proximo_ano')
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleDropdownMenu(dropdownKeyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const items = [
    {
      label: 'Próximos 18 meses',
      key: 'proximos_18_meses',
      onClick: () => handleDropdownMenu('proximos_18_meses'),
      'data-testid': `${dataTestPrefix}-proximos-18-meses`,
    },
    {
      label: 'Este ano',
      key: 'este_ano',
      onClick: () => handleDropdownMenu('este_ano'),
      'data-testid': `${dataTestPrefix}-este-ano`,
    },
    {
      label: 'Próximo ano',
      key: 'proximo_ano',
      onClick: () => handleDropdownMenu('proximo_ano'),
      'data-testid': `${dataTestPrefix}-proximo-ano`,
    },
  ];

  return (
    <CardContainer
      {...dataTestIdHandler(dataTestPrefix, 'container')}
      {...rest}
    >
      <Skeleton
        loading={amountsReceivableSummaryLoading}
        active
        paragraph={{
          rows: 8,
        }}
      >
        <Row align="middle" justify="space-between">
          <StHoverClickTitle onClick={goToAmountsReceivable}>
            <Col>
              <TitleAndIcon
                iconUri={amountsReceivableIconUri}
                iconAlt="Icone de cifrão"
                titleText="Valores a receber"
                titleFontSize="20px"
                dataTestPrefix={dataTestPrefix}
              />
            </Col>
          </StHoverClickTitle>
          <Col {...dataTestIdHandler(dataTestPrefix, 'dropdown')}>
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              dropdownRender={(menu) => (
                <StDropdownContent>{menu}</StDropdownContent>
              )}
            >
              <StDashboardTag icon={<CalendarOutlined />}>
                {dropdownKeyValue === 'proximos_18_meses' &&
                  'Próximos 18 meses'}
                {dropdownKeyValue === 'este_ano' && 'Este ano'}
                {dropdownKeyValue === 'proximo_ano' && 'Próximo ano'}
              </StDashboardTag>
            </Dropdown>
          </Col>
        </Row>
        <Row justify="end">
          <StHoverClickValue onClick={goToAmountsReceivable} disableHoverColor>
            <Col style={{ paddingTop: '1rem' }}>
              <LabelAndValue
                label="Total a receber"
                labelColor="rgba(0,0,0,0.45)"
                value={totalNetValue}
                valueTextSize="large"
                textAlign="end"
                dataTestPrefix={dataTestPrefix}
              />
            </Col>
          </StHoverClickValue>
        </Row>
        <Row>
          <Col span={24}>
            <MonthlyBarChart
              id="amountsReceivableRechart"
              data={forecast}
              dataKeyAxis="month"
              dataKeyBar="value"
              loading={amountsReceivableSummaryLoading}
              dataTestPrefix={dataTestPrefix}
            />
          </Col>
        </Row>
      </Skeleton>
    </CardContainer>
  );
};

AmountsReceivableCard.propTypes = {
  startDateAmountsReceivable: PropTypes.instanceOf(dayjs).isRequired,
  endDateAmountsReceivable: PropTypes.instanceOf(dayjs).isRequired,
  setStartDateAmountsReceivable: PropTypes.func.isRequired,
  setEndDateAmountsReceivable: PropTypes.func.isRequired,
};

export default AmountsReceivableCard;
