/* eslint-disable no-unused-expressions */
import plans from 'constants/plans';
import profiles from 'constants/profiles';
import scopes from 'constants/scopes';
import skins from 'constants/skins';
import store from 'store';

export const isClient = () => {
  const { permissions } = store.getState();
  const scopeId = permissions?.data?.scope_id;
  if (scopeId) {
    return scopeId === scopes.CLIENTE;
  }
  return false;
};

export const isPartner = () => {
  const { permissions } = store.getState();
  const scopeId = permissions?.data?.scope_id;
  if (scopeId) {
    return scopeId === scopes.PARCEIRO;
  }
  return false;
};

export const isConciler = () => {
  const { permissions } = store.getState();
  const scopeId = permissions?.data?.scope_id;
  if (scopeId) {
    return scopeId === scopes.CONCIL;
  }
  return false;
};

export const isPagSeguro = () => {
  const { permissions } = store.getState();
  const scopeId = permissions?.data?.scope_id;
  if (scopeId) {
    return scopeId === scopes.PAGSEGURO;
  }
  return false;
};

export const isOperational = () => {
  const { permissions } = store.getState();
  const profileId = permissions?.data?.profile_id;
  if (profileId) {
    return profileId === profiles.OPERACIONAL;
  }
  return false;
};

export const isManagerial = () => {
  const { permissions } = store.getState();
  const profileId = permissions?.data?.profile_id;
  if (profileId) {
    return profileId === profiles.GERENCIAL;
  }
  return false;
};

export const isAdmin = () => {
  const { permissions } = store.getState();
  const profileId = permissions?.data?.profile_id;
  if (profileId) {
    return profileId === profiles.ADMINISTRADOR;
  }
  return false;
};

export const isSuperAdmin = () => {
  const { permissions } = store.getState();
  const profileId = permissions?.data?.profile_id;
  if (profileId) {
    return profileId === profiles.SUPERADMIN;
  }
  return false;
};

export const isControle = () => {
  const { permissions } = store.getState();
  const planId = permissions?.data?.plan_id;
  if (planId) {
    return planId === plans.CONTROLE;
  }
  return false;
};

export const isGerencie = () => {
  const { permissions } = store.getState();
  const planId = permissions?.data?.plan_id;
  if (planId) {
    return planId === plans.GERENCIE;
  }
  return false;
};

export const isAdministre = () => {
  const { permissions } = store.getState();
  const planId = permissions?.data?.plan_id;
  if (planId) {
    return planId === plans.ADMINISTRE;
  }
  return false;
};

export const isAdministrePlus = () => {
  const { permissions } = store.getState();
  const planId = permissions?.data?.plan_id;
  if (planId) {
    return planId === plans.ADMINISTRE_PLUS;
  }
  return false;
};

export const isPagHub = () => {
  const { permissions } = store.getState();
  const planId = permissions?.data?.plan_id;
  if (planId) {
    return planId === plans.PAG_HUB;
  }
  return false;
};

// A more general skin verifying function in order to avoid copy pasting
export const isSkin = (givenSkin) => {
  const { permissions } = store.getState();
  const skinId = permissions?.data?.skin_id;
  if (skinId) {
    return skinId === skins[givenSkin];
  }
  return false;
};

export const isPagSeguroApp = () => {
  const { permissions } = store.getState();
  const skinId = permissions?.data?.skin_id;
  if (skinId) {
    return skinId === skins.PAGSEGURO_APP;
  }
  return false;
};

export const isSkinConcil = () => {
  const { permissions } = store.getState();
  const skinId = permissions?.data?.skin_id;
  if (skinId) {
    return skinId === skins.CONCIL;
  }
  return false;
};
