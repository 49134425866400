import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Upload, Button, Typography, notification } from 'antd';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { uploadFileStart } from '../../../../store/ducks/importFiles/actions';
import errorHandler from 'helpers/errorHandler';
import { StWarningIcon, StWarningRow, StWarningText } from './styled';
import { colors } from 'styles/colors';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const ImportFile = ({ fileType }) => {
  const { Text } = Typography;
  const dataTestPrefix = 'import-file';
  const uploading = useSelector((state) => state.importFiles.loadingUpload);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [fileSizeNotAllowed, setFileSizeNotAllowed] = useState(false);
  const MAX_FILE_SIZE = 3145728; // 3mb
  const dispatch = useDispatch();

  const onUploadFile = async () => {
    if (selectedFile === undefined) {
      errorHandler('Selecione um arquivo para envio');
    } else {
      dispatch(
        uploadFileStart({
          fileName: selectedFileName,
          fileType: fileType,
          file: selectedFile,
        })
      );
      setSelectedFileName('');
      setSelectedFile(undefined);
    }
  };
  const onSelectFile = ({ file }) => {
    setSelectedFileName(file.name);
  };
  const onBeforeUpload = (file) => {
    if (file?.size <= MAX_FILE_SIZE) {
      setSelectedFile(file);
      setFileSizeNotAllowed(false);
    } else {
      setFileSizeNotAllowed(true);
      notification.error({
        message: 'Arquivo excede o tamanho limite de 3MB.',
        props: dataTestIdHandler('error', 'notification'),
      });
    }
    return false;
  };

  return (
    <div style={{ marginBottom: '2rem' }}>
      <div>
        <Text strong style={{ marginTop: '2rem', fontSize: 20 }}>
          Enviar arquivo
        </Text>
      </div>
      <div>
        <Text style={{ fontSize: 14, color: colors.gray8 }}>
          Adicione o extrato em <b>formato {fileType}</b> que deseja analisar em
          nossa plataforma. O tamanho máximo permitido do arquivo é de 3MB.
        </Text>
      </div>
      <div style={{ marginTop: '1.75rem' }}>
        <Row>
          <Upload
            onChange={onSelectFile}
            showUploadList={false}
            multiple={false}
            beforeUpload={onBeforeUpload}
            openFileDialogOnClick
            accept=".ofx"
          >
            <Search
              placeholder="Selecione o arquivo em seu dispositivo"
              enterButton={
                <Button
                  {...dataTestIdHandler(dataTestPrefix, 'select-button')}
                  type="primary"
                >
                  Selecionar
                </Button>
              }
              value={selectedFileName}
              style={{ width: 544 }}
            />
          </Upload>
          <Button
            style={{ marginLeft: '3rem', borderRadius: '4px' }}
            onSubmit={onBeforeUpload}
            onClick={onUploadFile}
            loading={uploading}
            disabled={uploading || fileSizeNotAllowed}
            icon={<UploadOutlined />}
            {...dataTestIdHandler(dataTestPrefix, 'send-button')}
          >
            Enviar
          </Button>
        </Row>
        <StWarningRow>
          <StWarningIcon />
          <StWarningText>
            Certifique-se de importar todos os extratos do período desejado.
          </StWarningText>
        </StWarningRow>
      </div>
    </div>
  );
};

ImportFile.defaultProps = {};

ImportFile.propTypes = {
  fileType: PropTypes.string.isRequired,
};

export default ImportFile;
