/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { reportExtensions } from 'constants/reportExtensions';
import { createNotification } from 'services/notifications';
import errorHandler from 'helpers/errorHandler';
import dayjs from 'dayjs';
import { PermissionsContext } from 'routes/PrivateRoute';

// TODO: this hook should be refactored to use camelcase
export const useReport = ({
  fileName,
  table,
  startDate,
  endDate,
  toParamsFilters,
  message = 'Preparando relatório',
  description = 'Estamos preparando seu relatório. Assim que ele estiver pronto, ficará disponível para download na área de notificações.',
  clientName = undefined,
  extraFilters = undefined,
  sortFields = undefined,
}) => {
  const [dropdownOption, setDropdownOption] = useState('');
  const [loading, setLoading] = useState(false);

  const { permissions } = useContext(PermissionsContext);
  const { scope_id: scopeId, profile_id: profileId } = permissions;

  // eslint-disable-next-line consistent-return
  const createNewNotification = async (config) => {
    setLoading(true);

    try {
      await createNotification(config);
      setDropdownOption('');

      return notification.success({
        message,
        duration: 8,
        description,
        props: {
          'data-testid': 'notification-preparing-report',
        },
      });
    } catch (error) {
      errorHandler('Ocorreu um erro:', error);
      setDropdownOption('');
    } finally {
      setLoading(false);
    }
  };

  const buildExtraFilters = (filtersObj) => {
    const result = Object.entries(filtersObj)
      .filter(([k, v]) => k !== undefined && v !== undefined && v !== '')
      .map(([key, value]) => {
        if (key === 'codClient') {
          return {
            name: 'cod_client',
            val: [value],
          };
        }

        if (key === 'stage') {
          return {
            name: 'stage',
            val: value ? ['PRODUÇÃO'] : ['IMPLANTAÇÃO'],
          };
        }

        return {
          name: key,
          val: [value],
        };
      });

    return result;
  };

  const formattedFileName = `${fileName}.${reportExtensions[dropdownOption]}`;

  const format = 'YYYY-MM-DD';

  const notificationConfig = {
    message: `Estamos preparando seu arquivo ${formattedFileName} para o download.`,
    table,
    fileType: reportExtensions[dropdownOption],
    startDate: startDate ? dayjs(startDate).format(format) : undefined,
    endDate: endDate ? dayjs(endDate).format(format) : undefined,
    q: {
      filter: toParamsFilters
        ? exportFileParser(toParamsFilters)
        : extraFilters
          ? buildExtraFilters(extraFilters)
          : [],
    },
    clientName,
    profileId,
    scopeId,
    sortFields: JSON.stringify(sortFields),
  };

  useEffect(() => {
    if (dropdownOption === 'excel' || dropdownOption === 'csv') {
      createNewNotification(notificationConfig);
    }
  }, [dropdownOption]);

  return {
    dropdownOption,
    setDropdownOption,
    loading,
  };
};
