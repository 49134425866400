/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Row, Col, notification } from 'antd';
import { generalListOfClientsStart } from 'store/ducks/generalListOfClients/actions';
import UserTable from './components/UserTable';
import GeneralUserManagementHeader from './components/GeneralUserManagementHeader';
import { ExportDropdown } from 'commons/ExportDropdown';
import { getSessionClientId } from 'helpers/sessionService';
import { MAX_CSV_ROWS, MAX_EXCEL_ROWS } from 'constants/general';
import {
  StContainer,
  StHeaderContainer,
  StHeaderSubtitle,
  StHeaderTitle,
  StTableContainer,
  StButton,
  StClearFiltersButton,
} from './styled';
import {
  createNotificationStart,
  getNotificationStart,
} from 'store/ducks/notifications/actions';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const GeneralUserManagement = () => {
  const dispatch = useDispatch();
  const dataTestPrefix = 'general-user-management';
  const permissionsData = useSelector((state) => state.permissions?.data);
  const clientId = getSessionClientId();
  const { user_id: userId, scope_id: scopeId } = permissionsData;
  const [dropdownOption, setDropdownOption] = useState('');
  const [disableExcel, setDisableExcel] = useState(false);
  const [disableCSV, setDisableCSV] = useState(false);

  const createNotificationLoading = useSelector(
    (state) => state.notifications?.loading
  );

  const [fieldSearch, setFieldSearch] = useState('');
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const [filterType, setFilterType] = useState('client');
  const [clientSearchText, setClientSearchText] = useState('');
  const [partnerIdSearchText, setPartnerIdSearchText] = useState('');
  const [userSearchText, setUserSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);

  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);

  const createNotificationSuccess = useSelector(
    (state) => state.notifications?.success
  );

  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [pageTotal, setPageTotal] = useState(100);
  // eslint-disable-next-line no-unused-vars
  const [pageCurrent, setPageCurrent] = useState(1);
  const generalListOfClientsMeta = useSelector(
    (state) => state.generalListOfClients?.meta
  );

  useEffect(() => {
    setPerPage(generalListOfClientsMeta?.results_per_page);
    setPageTotal(generalListOfClientsMeta?.num_results);
    setPageCurrent(generalListOfClientsMeta?.current_page);
    if (generalListOfClientsMeta?.num_results > MAX_EXCEL_ROWS) {
      setDisableExcel(true);
    } else {
      setDisableExcel(false);
    }
    if (generalListOfClientsMeta?.num_results > MAX_CSV_ROWS) {
      setDisableCSV(true);
    } else {
      setDisableCSV(false);
    }
  }, [generalListOfClientsMeta]);

  const generalListOfClients = useSelector(
    (state) => state.generalListOfClients?.clients
  );

  const generalListOfClientsLoading = useSelector(
    (state) => state.generalListOfClients?.loading
  );

  useEffect(() => {
    dispatch(
      generalListOfClientsStart({
        filterType: filterType,
        filter: '',
        page: 1,
        perPage: 10000,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onClientSearch = () => {
    setFilterType('client');
    setFieldSearch(clientSearchText);
    dispatch(
      generalListOfClientsStart({
        filterType: 'client',
        filter: clientSearchText,
        page: 1,
        perPage: 10000,
      })
    );
  };

  const onPartnerIdSearch = () => {
    setFilterType('partner_id');
    setFieldSearch(partnerIdSearchText);
    dispatch(
      generalListOfClientsStart({
        filterType: 'partner_id',
        filter: partnerIdSearchText,
        page: 1,
        perPage: 10000,
      })
    );
  };

  const onUserSearch = () => {
    setFilterType('email');
    setFieldSearch(userSearchText);
    dispatch(
      generalListOfClientsStart({
        filterType: 'email',
        filter: userSearchText,
        page: 1,
        perPage: 10000,
      })
    );
  };

  const onClientSearchInputChange = (e) => {
    setClientSearchText(e.target.value);
  };

  const onPartnerIdSearchInputChange = (e) => {
    setPartnerIdSearchText(e.target.value);
  };

  const onUserSearchInputChange = (e) => {
    setUserSearchText(e.target.value);
  };

  const onClearFilters = () => {
    setClientSearchText('');
    setPartnerIdSearchText('');
    setUserSearchText('');
    setFieldSearch('');
    setFilterType('client');
    dispatch(
      generalListOfClientsStart({
        filterType: 'client',
        filter: '',
        page: 1,
        perPage: 10000,
      })
    );
  };

  const fileName =
    'relatorio_adm_geral_usuarios.' +
    (dropdownOption === 'excel' ? 'xlsx' : 'csv');

  const notificationConfig = {
    message: `Estamos preparando seu arquivo ${fileName} para o download.`,
    table: 'all-users',
    fileType: dropdownOption === 'excel' ? 'xlsx' : 'csv',
    filterType,
    filter: fieldSearch,
  };

  useEffect(() => {
    if (dropdownOption === 'excel' || dropdownOption === 'csv') {
      dispatch(createNotificationStart(notificationConfig));
      setDropdownOption('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOption]);

  useEffect(() => {
    if (createNotificationSuccess) {
      dispatch(
        getNotificationStart({
          userId,
          scopeId,
          clientId,
          page: 1,
          perPage: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNotificationSuccess]);

  return (
    <StContainer $breakpoint={breakpoint}>
      <StHeaderContainer span={24}>
        <GeneralUserManagementHeader
          onClientSearch={onClientSearch}
          onPartnerIdSearch={onPartnerIdSearch}
          onUserSearch={onUserSearch}
          onClientSearchInputChange={onClientSearchInputChange}
          onPartnerIdSearchInputChange={onPartnerIdSearchInputChange}
          onUserSearchInputChange={onUserSearchInputChange}
          clientSearchText={clientSearchText}
          partnerIdSearchText={partnerIdSearchText}
          userSearchText={userSearchText}
        />
      </StHeaderContainer>
      <StTableContainer span={24}>
        <Row justify="start">
          <Col>
            <StHeaderTitle>Resultados</StHeaderTitle>
          </Col>
        </Row>
        <Row gutter={8} justify="space-between" align="middle">
          <Col>
            <StHeaderSubtitle>
              Visualize seus resultados ou filtre a base de dados abaixo para
              administrar usuários
            </StHeaderSubtitle>
          </Col>
          <Col>
            <Row gutter={8} justify="end">
              <Col>
                <StClearFiltersButton onClick={onClearFilters}>
                  Limpar filtros
                </StClearFiltersButton>
              </Col>
              <Col>
                <ExportDropdown
                  loading={createNotificationLoading}
                  setDropdownOption={setDropdownOption}
                  disableExcel={disableExcel}
                  disableCSV={disableCSV}
                  dataTestPrefix="table-header"
                  {...dataTestIdHandler('table-header', 'export-button')}
                />
              </Col>
              <Col>
                <StButton
                  type="primary"
                  onClick={() => {
                    if (selectedRows.length >= 1) {
                      setRemoveModalVisible(true);
                    } else {
                      notification.warning({
                        message: 'Não há usuário(s) selecionado(s).',
                      });
                    }
                  }}
                  {...dataTestIdHandler(dataTestPrefix, 'delete-all')}
                >
                  Excluir selecionados ({selectedRowCount})
                </StButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '12px' }}>
          <Col>
            <UserTable
              generalListOfClients={generalListOfClients}
              generalListOfClientsLoading={generalListOfClientsLoading}
              setSelectedRowCount={setSelectedRowCount}
              isRemoveModalVisible={isRemoveModalVisible}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setRemoveModalVisible={setRemoveModalVisible}
            />
          </Col>
        </Row>
      </StTableContainer>
    </StContainer>
  );
};

export default GeneralUserManagement;
