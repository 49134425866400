import plans from './plans';
import { privateRoutes } from './privateRoutes';

const planControleRoutes = privateRoutes.reduce((acc, route) => {
  if (route.isActive && route.planPermission.includes(plans.CONTROLE)) {
    acc.push(route.path);
  }
  return acc;
}, []);

const planGerencieRoutes = privateRoutes.reduce((acc, route) => {
  if (route.isActive && route.planPermission.includes(plans.GERENCIE)) {
    acc.push(route.path);
  }
  return acc;
}, []);

const planAdministrePlusRoutes = privateRoutes.reduce((acc, route) => {
  if (route.isActive && route.planPermission.includes(plans.ADMINISTRE_PLUS)) {
    acc.push(route.path);
  }
  return acc;
}, []);

const planPagGratisRoutes = privateRoutes.reduce((acc, route) => {
  if (route.isActive && route.planPermission.includes(plans.PAG_GRATIS)) {
    acc.push(route.path);
  }
  return acc;
}, []);

const planFreeRoutes = privateRoutes.reduce((acc, route) => {
  if (route.isActive && route.planPermission.includes(plans.FREE)) {
    acc.push(route.path);
  }
  return acc;
}, []);

const planProRoutes = privateRoutes.reduce((acc, route) => {
  if (route.isActive && route.planPermission.includes(plans.PRO)) {
    acc.push(route.path);
  }
  return acc;
}, []);

const planPagHubRoutes = privateRoutes.reduce((acc, route) => {
  if (route.isActive && route.planPermission.includes(plans.PAG_HUB)) {
    acc.push(route.path);
  }
  return acc;
}, []);

export const contextRoutes = {
  // CONTROLE
  1: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planControleRoutes],
  },
  // GERENCIE
  2: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planGerencieRoutes],
  },
  // ADMINISTRE
  3: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planAdministrePlusRoutes],
  },
  // ADMINISTRE_PLUS
  4: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planAdministrePlusRoutes],
  },
  // PAG_GRATIS
  6: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planPagGratisRoutes],
  },
  // FREE
  7: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planFreeRoutes],
  },
  // PRO
  8: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planProRoutes],
  },
  // PAG_HUB
  9: {
    defaultRoute: '/dashboard',
    accessRoutes: [...planPagHubRoutes],
  },
};
