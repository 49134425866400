import React from 'react';
import Col from 'antd/es/grid/col';
import PropTypes from 'prop-types';
import { StContainer, StMainTitle } from './styled';
import SearchHeader from './components/SearchHeader';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';

const GeneralUserManagementHeader = ({
  onClientSearch,
  onPartnerIdSearch,
  onUserSearch,
  onClientSearchInputChange,
  onPartnerIdSearchInputChange,
  onUserSearchInputChange,
  clientSearchText,
  partnerIdSearchText,
  userSearchText,
}) => {
  return (
    <StContainer align="middle" justify="space-between">
      <Col span={24}>
        <StMainTitle {...dataTestIdHandler('general-user-management', 'title')}>
          Consultar acessos
        </StMainTitle>
      </Col>
      <Col span={24}>
        <SearchHeader
          onClientSearch={onClientSearch}
          onPartnerIdSearch={onPartnerIdSearch}
          onUserSearch={onUserSearch}
          onClientSearchInputChange={onClientSearchInputChange}
          onPartnerIdSearchInputChange={onPartnerIdSearchInputChange}
          onUserSearchInputChange={onUserSearchInputChange}
          clientSearchText={clientSearchText}
          partnerIdSearchText={partnerIdSearchText}
          userSearchText={userSearchText}
        />
      </Col>
    </StContainer>
  );
};

GeneralUserManagementHeader.propTypes = {
  onClientSearch: PropTypes.func.isRequired,
  onPartnerIdSearch: PropTypes.func.isRequired,
  onUserSearch: PropTypes.func.isRequired,
  onClientSearchInputChange: PropTypes.func.isRequired,
  onPartnerIdSearchInputChange: PropTypes.func.isRequired,
  onUserSearchInputChange: PropTypes.func.isRequired,
  clientSearchText: PropTypes.string.isRequired,
  partnerIdSearchText: PropTypes.string.isRequired,
  userSearchText: PropTypes.string.isRequired,
};

export default GeneralUserManagementHeader;
